import React, { useEffect, useRef, useState } from 'react';
import UploadSignatureFile from './UploadSignatureFile';
import moment from "moment";
import SignaturePadComponent from './SignaturePadComponent';
const DigitalSignatureComponent = ({signaturePadRef,fileInputRef,formik,readOnly,readOnlyData,setIsSig,ipAddress,setIpAddress,cDate,setCDate}) => {
  const [signatureType, setSignatureType] = useState(1);
  const [fetchDateNIp, setFetchDateNIp] = useState(false);
  
  useEffect(() => {
    console.log("first request",readOnly)
    if(fetchDateNIp && !readOnly){
      fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        console.log('Your IP address is:', data.ip);
        setIpAddress(data.ip);
        formik.setFieldValue('ip', data.ip)
      })
    .catch(error => {
      console.error('Error fetching IP address:', error);
    });
    setCDate(moment(new Date().getTime()).format('DD MMM YYYY, HH:mm'))
    formik.setFieldValue('signature_date',new Date().getTime())
    }else if(!readOnly){
      setIpAddress('')
      setCDate('')
    }
  },[fetchDateNIp])
  
    return (
        <div className='mb-3'>
            <div className="mt-3 mb-1">Signature*</div>
                <div className={`${readOnly ? 'd-none' : 'd-flex'} gap-2 mb-1 py-1`}>
                  <div className="border-b-black" style={{borderBottom : signatureType == 0 ? '1px #467FCF solid' : '0',color : signatureType == 0 ? '#467FCF' : fetchDateNIp || readOnly ? 'gray' : 'black',cursor: readOnly ? "" : 'pointer'}} onClick={() => {!fetchDateNIp && !readOnly && setSignatureType(0)}}>
                    <i className="fal fa-signature"></i>
                    <span className="ml-1">Draw</span>
                  </div>
                  <div className="border-b-black" style={{borderBottom : signatureType == 1 ? '1px #467FCF solid' : '0',color : signatureType == 1 ? '#467FCF' : fetchDateNIp || readOnly ? 'gray' : 'black',cursor: readOnly ? "" : 'pointer'}} onClick={() => {!fetchDateNIp && !readOnly && setSignatureType(1)}}>
                    <i className="fal fa-image"></i>
                    <span className="ml-1">Upload Image </span>
                  </div>
                </div>
                {signatureType == 0 && (
                  <>
                    <SignaturePadComponent setIsSig={setIsSig} readOnly={readOnly} setShowIp={setFetchDateNIp} formik={formik} showIp={fetchDateNIp} signaturePadRef={signaturePadRef}/>
                    {/* <i className="fal fa-info fa-circle"></i> */}
                  </>
                )}
                {signatureType == 1 && 
                  <UploadSignatureFile setIsSig={setIsSig} readOnly={readOnly} readOnlyData={readOnlyData} setShowIp={setFetchDateNIp} formik={formik} showIp={fetchDateNIp} fileInputRef={fileInputRef}/>
                }
                <div className='mt-2'>
                <span><i className='fal fa-info-circle mr-1'></i><b>IP:</b> {readOnly ? readOnlyData?.ip : ipAddress}</span>
                <span className='ml-2'><b>Date: </b>{readOnly ? moment(parseInt(readOnlyData?.signature_date*1000))?.format('DD MMM YYYY, HH:mm') : cDate}</span>
                </div>
                {formik?.touched?.student_signature && formik?.errors?.student_signature ? (
                    <div className="invalid-feedback d-block">
                      {formik.errors.student_signature}
                    </div>
                  ) : null}
        </div>
    );
}
export default DigitalSignatureComponent;
import React, { useRef, useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { submitReRegistrationService } from '../../services/ReRegistrationService';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setReRegistrationsReload } from '../../store/actions';
import DigitalSignatureComponent from '../common/DigitalSignatureComponent';

const disclaimer = {
  "113":"Signal Processing 102 requires completion of Studio Sound Engineering 101",
  "114":"Modern Music Theory 102 requires completion of Modern Music Theory 101",
  "115":"Music Ear Training 102 requires completion of Music Ear Training 101",
  "116":"Music Production Technique 102 requires completion of Music Production Technique 101",
  "118":"Recording Projects 102 requires completion of Studio Sound Engineering 101",
  "120":"Music Production Software 110 requires completion of Music Production Software 101",
  "122":"Music Production Technique 201 requires completion of Music Production Technique 102",
  "123":"Studio Sound Engineering 201 requires completion of Recording Project 102 and Music Production Software 110",
  "124":"Modern Music Theory 201 requires completion of Modern Music Theory 102",
  "126":"Music Production Software 201 requires completion of Music Production Software 110",
  "128":"Live Sound Engineering 201 requires completion of Signal Processing 102 and Recording Project 102",
  "129":"Music Production Software 210 requires completion of Music Production Software 201",
  "130":"Audio Post Production 301 requires completion of Music Production Software 201, Studio Sound Engineering 201,  and Music Production Software 201 (No outstanding 1st year modules)",
  "133":"Audio Mastering Techniques 302 requires completion of Music Ear Training 201 and Studio Sound Engineering 201",  
  "135":"Recording Project 102 requires completion of Studio Sound Engineering 101",
  "136":"Music Business Entrepreneurship 301 requires completion of Music Business 201",
  "1001":"Media Music Composition 301 requires completion of Music Production Software 201 and Modern Music Theory 201 (No outstanding 1st year modules)",
  "1002":"Electronic Music Production 301 requires completion of Music Production Software 201 and Modern Music Theory 201 (No outstanding 1st year modules)",
  "1003":"Sound Synthesis and Design 301 requires completion of Music Production Software 201, Music Production Technique 201, and Studio Sound Engineering 201 (No outstanding 1st year modules)",
  "1004":"Studio Sound Engineering 301 requires completion of Studio Sound Engineering 201 and Music Production Software 201 (No outstanding 1st year modules)",
}
export default function SchoolOfEmendy(props) {
  const { reRegSubject, registration, setReadOnly, setStudentNewRegId, lateReg } = props;
  const [loading, setLoading] = useState(false);
  const [disableReg, setDisableReg] = useState(false);
  const [disableRep, setDisableRep] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const isReReg = useSelector(state=>state.isReReg);
  const reload = useSelector(state => state.registrations.reload);
  const [isSig, setIsSig] = useState("")
  const signaturePadRef = useRef(null);
  const fileInputRef = useRef(null);
  const [ipAddress, setIpAddress] = useState('');
  const [cDate,setCDate] = useState('')

  const ReRegisterSchema = Yup.object({
    student_signature : Yup.mixed().required('Signature is required'),
    term_condn: Yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
  })
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      rereg_academy: registration.academy,
      rereg_id: registration.id,
      registerFor: "",
      repeat: [""],
      elective: [""],
      term_condn: false,
      late_reg : lateReg,
      student_signature : isSig,
      ip: ipAddress,
      signature_date:parseInt(new Date(cDate).getTime()/1000)
    },
    validationSchema: ReRegisterSchema,
    onSubmit: (values) => {
      const sendData = {...values};
      sendData.repeat = sendData.repeat.join()
      sendData.elective = sendData.elective.join()
      const formData = new FormData();
    
    // Append all values from the form
    Object.keys(values).forEach(key => {
      // Special handling for repeat field to convert array to comma-separated string
      if ((key === 'repeat' || key === 'elective') && Array.isArray(values[key])) {
        formData.append(key, values[key].join(','));
      } else {
        formData.append(key, values[key]);
      }
    });
      submitReRegistrationService(formData).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Successfully Submitted",
          text: "This does not confirm approval of your re-registration!!",
          timer: 3000,
          onOpen: function () {
            Swal.showLoading();
          },
        }).then(function (result) {
          setLoading(true);
          setStudentNewRegId(res.data.studentNewRegId);
          setReadOnly(true);
          props.updateStatus();
          if(isReReg.isReReg===true){
            dispatch(setReRegistrationsReload(!reload));
            history.push("/reregStatus");
          }
        });
      });
    }
  });

  const { values, errors, touched, handleSubmit, handleBlur, setValues } = formik;

  const handleCheck = (e) => {
    setValues({
      ...values,
      term_condn: e.target.checked
    })
  }

  const handleChangeRegisterFor = (e) => {   
    
    if(e.target.value!=""){
      setValues({
        ...values,
        registerFor: e.target.value,
        repeat:[""]
      });
      setDisableRep(true);
    }else{
      setValues({
        ...values,
        registerFor: e.target.value
      });
      setDisableRep(false);
    }
  }

  /* Multiple  Repeat Field  start  */
  const handleAddRepeat = () => {
    const repeatList = [...values.repeat];
    repeatList.push("");
    setValues({ ...values, repeat: [...repeatList] });
  }

  const handleChangeRepeat = (e, idx) => {
    let RepeatList = [...values.repeat];
    RepeatList[idx] = e.target.value;
    let flag = true
    RepeatList.forEach((element)=>{
      if(element!=""){
        flag=false;
      }
    })
    if(flag){
      setDisableReg(false);
    }else{
      setDisableReg(true);
    }
    setValues({
      ...values,
      repeat: [...RepeatList]
    });
  }

  const handleDeleteRepeat = (index) => {
    const repeatList = [...values.repeat];
    repeatList.splice(index, 1);
    let flag = true
    repeatList.forEach((element)=>{
      if(element!=""){
        flag=false;
      }
    })
    if(flag){
      setDisableReg(false);
    }else{
      setDisableReg(true);
    }
    setValues({ ...values, repeat: [...repeatList] });
  }
  /* Multiple  Repeat Field  end  */

/* Multiple  Elective Field  start  */
const handleAddElective = () => {
  const electiveList = [...values.elective];
  electiveList.push("");
  setValues({ ...values, elective: [...electiveList] });
}

const handleChangeElective = (e, idx) => {
  let ElectiveList = [...values.elective];
  ElectiveList[idx] = e.target.value;  
  setValues({
    ...values,
    elective: [...ElectiveList]
  });
}

const handleDeleteElective = (index) => {
  const electiveList = [...values.repeat];
  electiveList.splice(index, 1);  
  setValues({ ...values, elective: [...electiveList] });
}
/* Multiple  Elective Field  start  */

  return (
    <div className="card-body">
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <div className="theme-form application-form">
            <div className="row">
              <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">Register For:</h5>
                <label>
                Should you have passed your current level, as per your transcript results, please choose the programme you would like to register for/register for the next level of your programme.
                </label>
                <div className="form-group inputfield col-6">
                <select
                          className={"form-control " + (touched.registerFor && errors.registerFor ? " is-invalid" : "")}
                          name="registerFor"
                          id="registerFor"
                          disabled={disableReg}
                          value={values.registerFor}
                          onChange={(e) => { handleChangeRegisterFor(e) }}
                        >
                          <option value="">---</option>
                          <option value="21">Higher Certificate in Game Design and Development</option>
                          <option value="22">Higher Certificate in Creative Music Technology</option>
                          <option value="23">Diploma in Music Technology (1st Year)</option>
                          <option value="24">Diploma in Music Technology (2nd Year)</option>
                          <option value="25">Diploma in Music Technology (3rd Year)</option>                                                   
                        </select>
                </div>
              </div>
              <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">Repeat:</h5>
                <label>
                  Refer to your transcript to determine which modules you will need to repeat. Select all appropriate modules.
                </label>
                <div className="form-group inputfield col-12">
                  {values.repeat.map((element, idx) => (
                    <div className="row" key={idx}>
                      <div className="col-6">
                        <select
                          className={"form-control " + (touched.repeat && errors.repeat ? " is-invalid" : "")}
                          name="repeat"
                          id="repeat"
                          value={element}
                          // disabled={disableRep}
                          onChange={(e) => { handleChangeRepeat(e, idx) }}
                        >
                          <option value="">---</option>
                          {values.repeat.includes("101") && values.repeat.indexOf("101") !== idx ? "" : <option value="101">Applied Digital Arts 101</option>}
                          {values.repeat.includes("102") && values.repeat.indexOf("102") !== idx ? "" : <option value="102">3D Modelling and Animation 101</option>}
                          {values.repeat.includes("103") && values.repeat.indexOf("103") !== idx ? "" : <option value="103">Game Design Practice 101</option>}
                          {values.repeat.includes("104") && values.repeat.indexOf("104") !== idx ? "" : <option value="104">Game Development and Scripting 101</option>}
                          {values.repeat.includes("105") && values.repeat.indexOf("105") !== idx ? "" : <option value="105">Contemporary Game Theory 101</option>}
                          {values.repeat.includes("106") && values.repeat.indexOf("106") !== idx ? "" : <option value="106">Audio for Game Design</option>}
                          {values.repeat.includes("107") && values.repeat.indexOf("107") !== idx ? "" : <option value="107">Audio Fundamentals 101</option>}
                          {values.repeat.includes("108") && values.repeat.indexOf("108") !== idx ? "" : <option value="108">Modern Music Theory 101</option>}
                          {values.repeat.includes("109") && values.repeat.indexOf("109") !== idx ? "" : <option value="109">Music Ear Training 101</option>}
                          {values.repeat.includes("110") && values.repeat.indexOf("110") !== idx ? "" : <option value="110">Music Production Technique 101</option>}
                          {values.repeat.includes("111") && values.repeat.indexOf("111") !== idx ? "" : <option value="111">Music Software Cubase 101</option>}
                          {values.repeat.includes("112") && values.repeat.indexOf("112") !== idx ? "" : <option value="112">Studio Sound Engineering 101</option>}
                          {values.repeat.includes("113") && values.repeat.indexOf("113") !== idx ? "" : <option value="113">Signal Processing 102</option>}
                          {values.repeat.includes("114") && values.repeat.indexOf("114") !== idx ? "" : <option value="114">Modern Music Theory 102</option>}
                          {values.repeat.includes("115") && values.repeat.indexOf("115") !== idx ? "" : <option value="115">Music Ear Training 102</option>}
                          {values.repeat.includes("116") && values.repeat.indexOf("116") !== idx ? "" : <option value="116">Music Production Technique 102</option>}
                          {values.repeat.includes("117") && values.repeat.indexOf("117") !== idx ? "" : <option value="117">Music Software FL Studio 101</option>}
                          {values.repeat.includes("118") && values.repeat.indexOf("118") !== idx ? "" : <option value="118">Recording Projects 102</option>}
                          {values.repeat.includes("119") && values.repeat.indexOf("119") !== idx ? "" : <option value="119">Music Production Software 101</option>}
                          {values.repeat.includes("120") && values.repeat.indexOf("120") !== idx ? "" : <option value="120">Music Production Software 110</option>}
                          {values.repeat.includes("121") && values.repeat.indexOf("121") !== idx ? "" : <option value="121">Music for Game Audio 102</option>}
                          {values.repeat.includes("122") && values.repeat.indexOf("122") !== idx ? "" : <option value="122">Music Production Technique 201</option>}
                          {values.repeat.includes("123") && values.repeat.indexOf("123") !== idx ? "" : <option value="123">Studio Sound Engineering 201</option>}
                          {values.repeat.includes("124") && values.repeat.indexOf("124") !== idx ? "" : <option value="124">Modern Music Theory 201</option>}
                          {values.repeat.includes("125") && values.repeat.indexOf("125") !== idx ? "" : <option value="125">Music Business 201</option>}
                          {values.repeat.includes("126") && values.repeat.indexOf("126") !== idx ? "" : <option value="126">Music Production Software 201</option>}
                          {values.repeat.includes("128") && values.repeat.indexOf("128") !== idx ? "" : <option value="128">Live Sound Engineering 201</option>}
                          {values.repeat.includes("129") && values.repeat.indexOf("129") !== idx ? "" : <option value="129">Music Production Software 210</option>}
                          {values.repeat.includes("130") && values.repeat.indexOf("130") !== idx ? "" : <option value="130">Audio Post Production 301</option>}
                          {values.repeat.includes("131") && values.repeat.indexOf("131") !== idx ? "" : <option value="131">Music Business 301</option>}
                          {values.repeat.includes("132") && values.repeat.indexOf("132") !== idx ? "" : <option value="132">Music Industry Praxis 301</option>}
                          {values.repeat.includes("133") && values.repeat.indexOf("133") !== idx ? "" : <option value="133">Audio Mastering Techniques 302</option>}
                          {values.repeat.includes("134") && values.repeat.indexOf("134") !== idx ? "" : <option value="134">Audio Ear Training 201</option>}
                          {values.repeat.includes("135") && values.repeat.indexOf("135") !== idx ? "" : <option value="135">Recording Project 102</option>}
                          {values.repeat.includes("136") && values.repeat.indexOf("136") !== idx ? "" : <option value="136">Music Business Entrepreneurship 301</option>}
                        </select>
                      </div>
                      {values.repeat.length > 1 && <div className="col-2">
                        <button onClick={() => { handleDeleteRepeat(idx) }} type="button" className="btn btn-danger rounded-circle" title="Delete"><i className="fal fa-trash-alt"></i></button>
                      </div>}
                    </div>
                  ))}
                  {/* Condition is added on 32 because above select tag has 33 options, only change this number if number of options change */}
                  {values.repeat.length > 32 ? "" : <div className="form-group m-0">
                    <button
                      className="btn btn-link p-0 btn-additional"
                      onClick={() => handleAddRepeat()}
                      // disabled={disableRep}
                    >
                      <i className="fal fa-plus"></i> Add additional line item
                    </button>
                  </div>}
                </div>
                {values.repeat.filter(item => item!="" && Object.keys(disclaimer).includes(item)).length > 0 && <div className='disclaimer-text'>Disclaimer : 
                  {values.repeat.filter(item => item!="" && Object.keys(disclaimer).includes(item)).map((item,idx)=>{
                    if(Object.keys(disclaimer).includes(item)){
                      return(
                        <>
                          <br />
                          <span>{`${idx+1}. ${disclaimer[item]}`}</span>
                        </>
                      )
                    }
                  })}                  
                </div>}
              </div>
              {values.registerFor == "25" ? <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">Repeat Electives:</h5>                
                <div className="form-group inputfield col-12">
                  {values.elective.map((element, idx) => (
                    <div className="row" key={idx}>
                      <div className="col-6">
                        <select
                          className={"form-control " + (touched.elective && errors.elective ? " is-invalid" : "")}
                          name="elective"
                          id="elective"
                          value={element}
                          onChange={(e) => { handleChangeElective(e, idx) }}
                        >
                          <option value="">---</option>
                          {values.elective.includes("1001") && values.elective.indexOf("1001") !== idx ? "" : <option value="1001">Media Music Composition 301</option>}
                          {values.elective.includes("1002") && values.elective.indexOf("1002") !== idx ? "" : <option value="1002">Electronic Music Production 301</option>}
                          {values.elective.includes("1003") && values.elective.indexOf("1003") !== idx ? "" : <option value="1003">Sound Synthesis and Design 301</option>}
                          {values.elective.includes("1004") && values.elective.indexOf("1004") !== idx ? "" : <option value="1004">Studio Sound Engineering 301</option>}
                        </select>
                      </div>
                      {values.elective.length > 1 && <div className="col-2">
                        <button onClick={() => { handleDeleteElective(idx) }} type="button" className="btn btn-danger rounded-circle" title="Delete"><i className="fal fa-trash-alt"></i></button>
                      </div>}
                    </div>
                  ))}
                  {((values.rereg_academy === "fg" || values.rereg_academy === "fst") && values.elective.length > 1) ? "" : <div className="form-group m-0">
                    <button
                      className="btn btn-link p-0 btn-additional"
                      onClick={() => handleAddElective()}                    
                    >
                      <i className="fal fa-plus"></i> Add additional line item
                    </button>
                  </div>}
                </div>
                {values.elective.filter(item => item!="" && Object.keys(disclaimer).includes(item)).length > 0 && <div className='disclaimer-text'>Disclaimer : 
                    {values.elective.filter(item => item!="" && Object.keys(disclaimer).includes(item)).map((item,idx)=>{
                      if(Object.keys(disclaimer).includes(item)){
                        return(
                          <>
                            <br />
                            <span>{`${idx+1}. ${disclaimer[item]}`}</span>
                          </>
                        )
                      }
                    })}                  
                  </div>}
              </div> : ""}
              <DigitalSignatureComponent formik={formik} fileInputRef={fileInputRef} signaturePadRef={signaturePadRef} setCDate={setCDate} setIpAddress={setIpAddress} cDate={cDate} ipAddress={ipAddress} setIsSig={setIsSig}/>
              <div className="col-12 form-group inputfield">
                <div className="custom-control custom-checkbox text-left box-tm">
                  <input
                    className={
                      "custom-control-input " +
                      (touched.term_condn && errors.term_condn
                        ? " is-invalid"
                        : "")
                    }
                    type="checkbox"
                    name="term_condn"
                    onChange={(e) => handleCheck(e)}
                    onBlur={handleBlur}
                    checked={values.term_condn}
                    id="customCheck1"
                  />

                  <label
                    className="custom-control-label textdanger"
                    htmlFor="customCheck1"
                  >
                    I hereby confirm that I have read, understood and accepted
                    all <a href='https://emendy.co.za/pgs-142-terms-and-conditions.html' target="_blank">Terms and Conditions</a> as set out in the application form that the above subject selections are correct, and understand
                    that there may be changes made based on the academic outcome.
                  </label>
                  {formik.touched.term_condn && formik.errors.term_condn ? (
                    <div className="invalid-feedback">
                      {formik.errors.term_condn}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="Fmfooter">
            <button
              title="Submit"
              type="submit"
              className="btn btn-submit btn-main btn-primary"
              disabled={loading}
            >
              {loading && <i className="fas fa-cog fa-spin"></i>} Submit
            </button>
            <button title="Cancel" className="btn btn-submit btn-main btn-danger ml-2">
              Cancel
            </button>
          </div>
        </Form>
      </FormikProvider>
    </div>
  )
}

import React, { useEffect, useState } from "react";
import { object } from "underscore";
import TeamProfile from "./TeamProfile";
import { GetSupportTeamService } from "../../services/QualificationService";
import SkeletonReRegistrationList from "../../loaders/SkeletonReRegistrationList";

export default function TeamBox(props) {
  const [team, setTeam] = useState([]);

  useEffect(() => {
    let mounted = true
    GetSupportTeamService({ sid: props.subjectId })
      .then((res) => {
        if (mounted) {
          setTeam(res.data.team);
        }
      })
      .catch((error) => { });

    return function cleanup() {
      mounted = false
    }
  }, []);

  return (
    <>
      {team.length === 0 && <div className="row support-team-skeleton"><SkeletonReRegistrationList /></div>}
      {team.length !== 0 ? (
        <div className="row">
          {team.coach && (
            <TeamProfile team={team.coach} profile="coach" />
          )}
          {team.lecturer && (
            <TeamProfile team={team.lecturer} profile="lecturer" />
          )}
          {team.support && (
            <TeamProfile team={team.support} profile="support" />
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

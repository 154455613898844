import React, { useEffect } from "react";
import Open from "../../assets/images/Open.png";
import Close from "../../assets/images/closed.png";
import { Link } from "react-router-dom";
import { getFormattedTimeForList } from "../common/Helper";

function ReRegistrationList(props) {
  const { registration } = props;
  useEffect(() => {
    console.log("AAAAAAAAAAAAAAAAAa",registration);
  },[registration])
  return (
    <>
      <li>
        <div className="card re-registration-bx-card">
          <Link
            title={registration.academy}
            to={"/reregistrationSchool/" + registration.id + "/" + registration.qualification_higher_enrolled}
            className="re-registration-link-redirect"
          ></Link>
          <div className="icon">SE</div>
          <h3>
            {registration.academy}
            <br /> Intake {registration.intake_number},{" "}
            {registration.intake_year}
          </h3>
          {registration.reg_status == "Closed" ? (
            <p>
              <i className="">
                <img src={Close} />
              </i>
              CLOSED
            </p>
          ) : (
            <p>
              <i className="">
                <img src={Open} />
              </i>
              OPEN
            </p>
          )}

          {registration.registrationStatus || registration.per? (
            <p className="green-color">
              <i className="fal fa-user-check"></i>Registration Submitted
            </p>
          ) : (
            <p className="red-color">
              <i className="fal fa-user-times"></i> Not Registered
            </p>
          )}

          {/* {registration.reRegistrationStatus === "Not Yet Registered" ? (
            <p className="red-color">
              <i className="fal fa-user-times"></i> Not Registered
            </p>
          ) : (registration.registrationStatus || (registration.per && registration.per!==""))  ? 
          <p className="green-color">
              <i className="fal fa-user-check"></i>Registration Submitted
            </p>
          :(
            <p className="red-color">
              <i className="fal fa-user-times"></i> Not Registered
            </p>
          )} */}

          <p>
            <i className="fal fa-calendar-alt"></i>
            {registration.start_date} to {registration.end_date}
          </p>
        </div>
      </li>
    </>
  );
}

export default ReRegistrationList;

function base64ToFile(base64String, filename, mimeType) {
    // Decode the base64 string
    const byteCharacters = atob(base64String.split(',')[1]);
    
    // Convert to byte array
    const byteArrays = [];
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }
    const byteArray = new Uint8Array(byteArrays);
    
    // Create a Blob
    const blob = new Blob([byteArray], { type: mimeType });
    
    // Create a File from the Blob
    return new File([blob], filename, { type: mimeType });
}

export {base64ToFile}
import React from "react";
//import $ from "jquery";
/* eslint-disable import/first */
var $ = require("jquery");
//require("jquery-mousewheel")($);
//require("malihu-custom-scrollbar-plugin")($);
import { Scrollbars } from "react-custom-scrollbars";
import { Scrollbar } from "react-scrollbars-custom";

import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";

import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import QualificationHeadBox from "../components/course/QualificationHeadBox";
import SubjectListBox from "../components/course/SubjectListBox";
import SubjectContent from "../components/course/SubjectContent";
import { connect } from "react-redux";
import { setCount, setSubject } from "../store/actions";
import {
  GetMySubjectsService,
  GetSubjectContentService,
} from "../services/QualificationService";
import { getToken } from "../utils/Auth";
/** WEB SOCKET  */
// import { WS_URL } from "../utils/Constants";
// import Ws from "@adonisjs/websocket-client";
import SkeletonSubject from "../loaders/SkeletonSubject";
import SkeletonQaulificationBox from "../loaders/SkeletonQualificationBox";
import not_yet_published_icon from "../assets/images/not_yet_published_icon.svg";
import SekletonSubjectList from "../loaders/SekletonSubjectList";

import moment from "moment-timezone";
import SkeletonSubjectHeadBox from "../loaders/SekletonSubjectHeadBox";
import SubjectHeadBox from "../components/course/SubjectHeadBox";
// moment.tz.setDefault("Africa/Johannesburg");
let ws = null;
//let chat = null;
/** map actions */
function mapDispatchToProps(dispatch) {
  return {
    setSubject: (payload) => dispatch(setSubject(payload)),
    setCount: (payload) => dispatch(setCount(payload)),
  };
}

class MyCourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qualification: {},
      subjects: [],
      completed_subjects: [],
      subjectContent: {},
      chat: {},
      loading: false,
      active: 0,
      initialClick: false,
      subjectId: 0,
      reload: 0,
      subjectListLoading: true,
      reduceCommentCount: 0,
      room_id: 0,
      isExamLaunched:false,
      assessmentId: 0,
      backToOverview : 0
    };
    this.showCompleted = React.createRef();
  }

  componentDidMount() {
    this.props.setCount(0);
    $("body").addClass("sub-sidebar-c");
    $("body").addClass("hide-subject-sidebar");

    const { match } = this.props;
    if(match?.params?.aid){
      this.setState({ assessmentId: match.params.aid });
    }
    this.setState({ loading: true });
    // alert(JSON.stringify(match.params));
    //console.log('Quali--------',match.params.sid);
    if (this.props.location.pathname.includes("shortCourse")) {
      // GetMySubjectsService(match.params)
      // .then((res) => {
      //   debugger;

      //   if (
      //     res.data.subjects.others.length <= 0 &&
      //     res.data.subjects.finised.length > 0
      //   ) {
      //     this.setState({ initialClick: true });
      //   }
      //   let initialID;
      //   if (match.params.sid == undefined) {
      //     initialID =
      //       res.data.subjects.others.length > 0
      //         ? res.data.subjects.others[0].id
      //         : res.data.subjects.finised.length > 0
      //           ? res.data.subjects.finised[0].id
      //           : 58;
      //   } else {
      //     initialID = match.params.sid;

      //   }
        this.getSubContentMethod(match.params.sid);
        this.setState({ subjectId: match.params.sid });
        // this.setState({
        //   qualification: res.data.qualification,
        //   subjects: res.data.subjects.others,
        //   completed_subjects: res.data.subjects.finised,
        // });
        this.setState({ subjectListLoading: false });
        // setTimeout(() => {
          this.setState({ loading: false });
        // }, 1000);
      // })
      // .catch((error) => {
      //   this.setState({ subjectListLoading: false });
      // });
    } else {
    GetMySubjectsService(match.params)
      .then((res) => {
        if(Object.keys(res.data.qualification).length){
          if (
            res.data.subjects.others.length <= 0 &&
            res.data.subjects.finised.length > 0
          ) {
            this.setState({ initialClick: true });
          }
          let initialID;
          if (match.params.sid == undefined) {
            initialID =
              res.data.subjects.others.length > 0
                ? res.data.subjects.others[0].id
                : res.data.subjects.finised.length > 0
                  ? res.data.subjects.finised[0].id
                  : 58;
          } else {
            initialID = match.params.sid;
  
          }
          this.getSubContentMethod(initialID);
          this.setState({ subjectId: initialID });
          this.setState({
            qualification: res.data.qualification,
            subjects: res.data.subjects.others,
            completed_subjects: res.data.subjects.finised,
          });
          this.setState({ subjectListLoading: false });
          setTimeout(() => {
            this.setState({ loading: false });
          }, 1000);
        }else{
          this.handleRedirectToCourse()
        }
      })
      .catch((error) => {
        this.handleRedirectToCourse();
        this.setState({ subjectListLoading: false });
      });
    }

    // $(".sub-detail-lft .sub-lft-body").mCustomScrollbar({
    //   theme: "dark-3",
    //   scrollButtons: {
    //     enable: true,
    //   },
    //   callbacks: {
    //     // onTotalScroll:function(){ addContent(this) },
    //     // onTotalScrollOffset:100,
    //     // alwaysTriggerOffsets:false
    //   },
    // });
  }

  updateSubjectOnly = () => {    
    const { match } = this.props;    
    GetMySubjectsService(match.params)
      .then((res) => {
        if(Object.keys(res.data.qualification).length){
          this.setState({
            qualification: res.data.qualification,
            subjects: res.data.subjects.others,
            completed_subjects: res.data.subjects.finised,
          });          
        }else{
          this.handleRedirectToCourse()
        }
      })
      .catch((error) => {
        console.log(error);
        this.handleRedirectToCourse();
      });
  };

  updateSubject = (isSLP) => {
    
    const { match } = this.props;
    if (isSLP) {
      GetMySubjectsService({ qid: match.params.sid, isSLP, sp_student_intake_id: this.state?.subjectContent?.subject?.sp_student_intake_id })
        .then((res) => {
          this.setState({ subjectContent:  res.data });
        })
        .catch((err) => console.log(err));
    } else {
      GetMySubjectsService(match.params)
        .then((res) => {
          if(Object.keys(res.data.qualification).length){
            this.setState({
              qualification: res.data.qualification,
              subjects: res.data.subjects.others,
              completed_subjects: res.data.subjects.finised,
            });
            GetSubjectContentService({ sid: this.state.subjectId })
              .then((res) => {
                this.setState({ subjectContent: res.data });
              })
              .catch((error) => {
                console.log(error);
                this.handleRedirectToCourse();
              });
          }else{
            this.handleRedirectToCourse()
          }
        })
        .catch((error) => {
          console.log(error);
          this.handleRedirectToCourse();
        });
    }
  };
  // shouldComponentUpdate() {
  //   return false;
  // }
  getSubContentMethod = (sid) => {
    //debugger;
    if (this.state.active !== sid) {
      // this.state.qualification.school_type !== "shortcourse" && $("body").removeClass("hide-subject-sidebar");
      $("body").removeClass("hide-subject-sidebar");
      this.setState({ subjectId: sid });
      GetSubjectContentService({ sid })
        .then((res) => {
          if(res.status==200){
            this.setState({ subjectContent: res.data });
            this.setState({ active: sid });
            if (this.state.initialClick) {
              this.showCompleted.current.click();
              this.setState({ initialClick: false });
            }
          }else{
            this.handleRedirectToCourse()
          }
        })
        .catch((error) => {this.handleRedirectToCourse()});
    } else {
      GetSubjectContentService({ sid })
        .then((res) => {
          if(res.status==200){
            this.setState({ subjectContent: res.data });
            this.setState({ active: sid });
            if (this.state.initialClick) {
              this.showCompleted.current.click();
              this.setState({ initialClick: false });
            }
          }else{
            this.handleRedirectToCourse()
          }
        })
        .catch((error) => {this.handleRedirectToCourse()});
    }
  };

  handleSideBar = () => {
    $("body").toggleClass("hide-subject-sidebar");
    if ($("body").hasClass("hide-subject-sidebar")) {
      $("body").removeClass("sub-sidebar-c");
    } else {
      $("body").addClass("sub-sidebar-c");
    }
  };

  reduceCount = (count, room_id) => {
    this.props.setCount(this.state.reduceCommentCount + count);
    this.setState({ reduceCommentCount: this.state.reduceCommentCount + count, room_id: room_id })

    let tempSub = this.state.subjects
    let comTempSub = this.state.completed_subjects

    tempSub.map(i => {
      if (i.id == room_id) {
        i.unreadMessage = i.unreadMessage - count
      }
    })
    comTempSub.map(i => {
      if (i.id == room_id) {
        i.unreadMessage = i.unreadMessage - count
      }
    })
    this.setState({
      subjects: tempSub,
      completed_subjects: comTempSub,
    });
  }

  handleRedirectToCourse(){
    this.props.history.push("/mycourses")
  }

  render() {
    return (
      <>
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap">
          <SideBar count={this.state.reduceCommentCount}></SideBar>
          <div className="sidebar-right">
            {this.state.subjectListLoading && <SekletonSubjectList />}
            {(Object.keys(this.state.qualification).length != 0 || this.props.location.pathname.includes("shortCourse")) && !this.state.subjectListLoading ? (
              <div className="subject-detail-wrap  h-100">
                {/* side bar */}
                {!this.props.location.pathname.includes("shortCourse") && <div className="sub-detail-lft bg-white">                                    
                  <button
                    className="close-btn-sidebar"
                    onClick={this.handleSideBar}
                  >
                    <i className="close-bar close-bar-1"></i>
                    <i className="close-bar close-bar-2"></i>
                    <i className="close-bar close-bar-3"></i>
                  </button>
                  {this.state.loading && <SkeletonQaulificationBox />}
                  {!this.state.loading && (
                    <QualificationHeadBox row={this.state.qualification} />
                  )}

                  <div className="sub-lft-body">
                    {/* <CustomScroll
                    addScrolledClass={true}
                    heightRelativeToParent="100%"
                    minScrollHandleHeight={10}
                  > */}
                    {/* <Scrollbars
                    hideTracksWhenNotNeeded={true}
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          backgroundColor: "#000",
                          opacity: "0.5",
                        }}
                        className="thumb-vertical"
                      />
                    )}
                  > */}
                    <Scrollbar>
                      <div className="subject-list">
                        {this.state.loading && <SkeletonSubject />}
                        {!this.state.loading && (
                          <SubjectListBox
                            rows={this.state.subjects}
                            parentGetSubContentMethod={this.getSubContentMethod}
                            reload={this.reload}
                            active={this.state.active}
                            qid={this.props.match.params.qid}
                            isExamLaunched={this.state.isExamLaunched}
                            setIsExamLaunchedToTrue={()=>this.setState({isExamLaunched:true})}
                            setIsExamLaunchedToFalse={()=>this.setState({isExamLaunched:false})}
                            changeSub = {()=>this.setState({backToOverview: this.state.backToOverview+1})}
                          />
                        )}
                      </div>
                      {this.state.completed_subjects.length ? (
                        <button
                          data-toggle="collapse"
                          data-target="#demo"
                          aria-expanded="false"
                          className="hide collapsed hide-completed"
                          ref={this.showCompleted}
                        ></button>
                      ) : null}
                      {this.state.completed_subjects.length ? (
                        <div className="subject-list collapse" id="demo">
                          <SubjectListBox
                            rows={this.state.completed_subjects}
                            parentGetSubContentMethod={this.getSubContentMethod}
                            active={this.state.active}
                            qid={this.props.match.params.qid}
                            changeSub = {()=>this.setState({backToOverview: this.state.backToOverview+1})}
                          />
                        </div>
                      ) : null}
                      <div id="spacer">&nbsp;</div>
                      {/* </Scrollbars> */}
                      {/* </CustomScroll> */}
                    </Scrollbar>
                  </div>
                </div>}
                {/* end of side bar */}

                <div className={this.props.location.pathname.includes("shortCourse") ? "sub-detail-full" : "sub-detail-rgt"}>
                    {this.state.subjectContent.subject ? (
                      this.state.subjectContent.subject.view_only != "show" && 
                        this?.state?.subjectContent?.subject?.publish >
                        moment().unix() ? (
                          <div className="blank-not-publish-con">
                          <div className="blank-chat-icon">
                            <i className="fad fa-times-square chat-icon-3"></i>
                            <i className="fad fa-upload chat-icon-4"></i>
                            <i className="fad fa-times-circle chat-icon-5"></i>
                          </div>
                          <div className="blank-chat-text">
                            <p>Course not yet published</p>
                            <p>Please try again closer to the start date</p>
                          </div>
                        </div>
                      ) : (
                        <SubjectContent
                          getSubContentMethod={() =>
                            this.getSubContentMethod(this.state.subjectId)
                          }
                          subjectId={this.state.subjectId}
                          isAdmin={0}
                          subjectContent={this.state.subjectContent}
                          updateSubject={this.updateSubject}
                          updateSubjectOnly={this.updateSubjectOnly}
                          reduceCount={this.reduceCount}
                          isExamLaunched={this.state.isExamLaunched}
                          setIsExamLaunchedToTrue={()=>this.setState({isExamLaunched:true})}
                          setIsExamLaunchedToFalse={()=>this.setState({isExamLaunched:false})}
                          assessmentId={this.state.assessmentId}
                          qid={this.props.match.params.qid}
                          backToOverview={this.state.backToOverview}
                        />
                      )
                    ) : null}
                  
                </div>
              </div>) : !this.state.subjectListLoading ? (
                <div className="subject-no-content">
                  <div className="no-content">
                    <h4>Unauthorized Access</h4>
                    <p>You don't have access to view this page.</p>
                  </div>
                </div>) : ('')}
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, mapDispatchToProps)(MyCourse);

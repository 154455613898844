import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import SkeletonTicketList from '../../loaders/SkeletonTicketList';
import { TABLE_ROWS_PER_PAGE } from '../../utils/Constants';
import Tablefilter from './Tablefilter';

// import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import { GlobalIconNames } from '../../utils/GlobalIconNames';
import { handleTableScroll } from '../../utils/commonFunction';

function DataTableComponentFrontPagination({onSort = 0, data = [], isExport=true, loading,changePage, search, setSearch, exportData, exportFileName, filters = [], tableButton = [], columns, defaultSort, isInsidePopUp = false, bottomButton = [], otherTableProps, isActionAlignRight = false, errorMessageMargin = false,custumcss, keyField="ref_id"}) {
    // const [col, setCol] = useState([]);

    // useEffect(() => {
    //     if (data.length) {
    // This Function is for dynamic columns but if implemented searching and filter will not work 
    //         let cols = Object.keys(data[0]).map((key, id) => {
    //             return {
    //                 name: key,
    //                 selector: key,
    //                 sortable:true,
    //                 cell: ()=> data[0][key].html
    //             }
    //         })
    //         setCol(cols)
    //     }
    // }, [data])

    useEffect(() => {
        handleTableScroll()
      }, [loading])

    const resetFilter = () => {
        filters.map(item => {
            item.setState({ arr: [], checkObj: item.isSearchFilter ? [] : {} })
        })
        setSearch("")
    }

    // const exportFunction = (fileType) => {
    //     let listData;
    //     const header = exportData().header;
    //     listData = exportData().data
    //     let data = exportData().data

    //     if (fileType === "csv") {
    //     const csvString = Papa.unparse({ fields: header, data });
    //       const blob = new Blob([csvString], {
    //         type: "text/csv;charset=utf-8,",
    //       });

    //       const blobURL = window.URL.createObjectURL(blob);

    //       // Create new tag for download file
    //       const anchor = document.createElement("a");
    //       anchor.download = exportFileName;
    //       anchor.href = blobURL;
    //       anchor.dataset.downloadurl = [
    //         "text/csv",
    //         anchor.download,
    //         anchor.href,
    //       ].join(":");
    //       anchor.click();

    //       setTimeout(() => {
    //         URL.revokeObjectURL(blobURL);
    //       }, 1000);
    //     } else if (fileType === "xlsx") {
    //         const compatibleData = listData.map((row) => {
    //             const obj = {};
    //             header.map((col, index) => {
    //                 obj[col] = row[col];
    //             });
    //             return obj;
    //         });

    //         let wb = XLSX.utils.book_new();
    //         let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
    //             header,
    //         });
    //         XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
    //         XLSX.writeFile(wb, `${exportFileName}.xlsx`);

    //         return false;
    //     }
    //     if (fileType === "pdf") {
    //         const compatibleData = exportData().pdfData
    //         const doc = new JsPDF();
    //         doc.autoTable({
    //             head: [header],
    //             body: compatibleData,
    //             columnStyles: exportData().columnStyles,
    //             styles: {
    //                 minCellHeight: 10,
    //                 minCellWidth: 5,
    //                 halign: "left",
    //                 // valign: "center",
    //                 fontSize: 8,
    //             },
    //         });
    //         doc.save(`${exportFileName}.pdf`);

    //         return false;
    //     }
    // };

    return (
        <div className="my-tickets-info-list Tickets-main-wrap">
            <div className={isInsidePopUp ? `custom-table-div filter-search-icon card-table-custom course-table-filter-sec ${isActionAlignRight ? "grade-mix-table" : ""}` : `custom-table-div filter-search-icon card card-table-custom course-table-filter-sec submisson-table-bg ${data.length == 1 ? "submisson-table-item1" : ""} ${errorMessageMargin ? "error-message-margin" : ""} ${custumcss}`}>
                <div className="search-filter-div">
                    <div className="search-filter-div-left">
                        <div className="system-administration-table table-responsive">
                            <div className="table-responsive-div">
                                <div
                                    id="assessment-table-main_wrapper"
                                    className="dataTables_wrapper no-footer"
                                >
                                    <div
                                        id="assessment-table-main_filter"
                                        className="dataTables_filter"
                                    >
                                        <label>
                                            <input
                                                type="search"
                                                className=""
                                                placeholder="Search"
                                                aria-controls="assessment-table-main"
                                                onChange={e => setSearch(e.target.value)}
                                                value={search}
                                            />
                                        </label>
                                        <div className="filter-eff filter-data-btn ml-2">
                                            <button className="filter-buttons">
                                            {GlobalIconNames("filter")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-button-group">
                                <div className="filter-scroll">
                                    <div className={`filter-scroll-inner  filter-custom-new`}>
                                        {filters.map((item, index) =>
                                            <div key={index}>
                                                <Tablefilter {...item} />
                                            </div>
                                        )
                                        }
                                    </div>
                                </div>
                                <div className="reset-btn-group">
                                    <div className="button-reset dropdown-comman">
                                        <button
                                            className="btn btn-primary"
                                            onClick={resetFilter}
                                            title="Reset"
                                            type='button'
                                        >
                                            {GlobalIconNames("reset").html}
                                        </button>
                                    </div>
                                   {isExport && <div className="files-export-group">
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            title="Export spreadsheet"
                                            onClick={() => {
                                                // exportFunction("xlsx");
                                            }}
                                        >
                                            {GlobalIconNames("excel")}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            title="Export CSV"
                                            onClick={() => {
                                                // exportFunction("csv");
                                            }}
                                        >
                                            {GlobalIconNames("csv")}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            title="Export PDF"
                                            onClick={() => {
                                                // exportFunction("pdf");
                                            }}
                                        >
                                            {GlobalIconNames("pdf")}
                                        </button>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="search-filter-div-right">
                        <div className=" filter-search-bar-blk">
                            <div className="add-ticket-blk button-reset dropdown-comman">
                                {tableButton.map((item, index) => (
                                    <div key={index}>
                                        {item}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {loading ? (
                    <SkeletonTicketList />
                ) : (
                    onSort ? <DataTable
                        data={data}
                        columns={columns}
                        pagination={true}
                        onSort={onSort}
                        onChangePage={changePage}
                        defaultSortField={defaultSort?.defaultSortColumn}
                        defaultSortAsc={defaultSort?.defaultSortAsc}
                        paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                        keyField={keyField}
                        {...otherTableProps}
                    /> : 
                    <DataTable
                        data={data}
                        columns={columns}
                        pagination={true}
                        onChangePage={changePage}
                        defaultSortField={defaultSort?.defaultSortColumn}
                        defaultSortAsc={defaultSort?.defaultSortAsc}
                        paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                        keyField={keyField}
                        {...otherTableProps}
                    />
                )}
                {bottomButton.map((item, index) => (
                    <div key={index}>
                        {item}
                    </div>
                ))}

            </div>

        </div>
    )
}

export default DataTableComponentFrontPagination
import React, { useState, useEffect } from "react";
import randomColor from "randomcolor";

export default function ShortNameBox({ qualification, paramColor }) {
  const [color, setColor] = useState(null);
  const [shortName, setShortName] = useState(null);
  useEffect(() => {
    setColor(
      randomColor({
        luminosity: "dark",
      })
    );
    if (qualification.course) {
      let names = qualification.course.split(" ");
      let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      if (Array.isArray(names) && names.length > 1) {
        let name;
        if (!format.test(names[1].charAt(0))) {
          name = `${names[0].charAt(0).toUpperCase()}${names[1]
            .charAt(0)
            .toUpperCase()}`;
        } else {
          name = `${names[0].charAt(0).toUpperCase()}`;
        }

        setShortName(name);
      } else {
        let name = `${names[0].charAt(0).toUpperCase()}`;
        setShortName(name);
      }
    }
  }, [qualification]);
  return (
    <>
      <div className="icon" style={{ backgroundColor: paramColor ? paramColor : color }}>
        {shortName}
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { download } from "../../utils/FileDownload";
import moment from "moment";
import randomColor from "randomcolor";
import PuzzleImg from '../../assets/images/puzzle-icon.svg';
import { TrimText } from "../common/TrimText";
import { getSubjectStatus } from "../../utils/commonFunction";

const stc = require('string-to-color');
const downloadFile = async (absolutePath, fileName) => {
  await download(absolutePath, fileName);
};

export default function QualificationBox({  qualification, state }) {
  const [color, setColor] = useState(null);

  useEffect(() => {
    setColor(
      randomColor({
        luminosity: "dark",
      })
    );
  }, [qualification])

  function createAbbreviation(name = "") {
    return name.split(" ")[0].toString().toUpperCase().substring(0, 1) + (name.split(" ")[1] ? name.split(" ")[1].toString().toUpperCase().substring(0, 1) : "");
  }

  return (
    <>
      <div className="content-wrapper d-flex flex-wrap without-header w-100">
        <div className="sidebar-right">
          <div className="subject-detail-wrap d-flex h-100">
            <div className="lecture-tabs-wrap">
              <div className="tab-content" id="pills-tabContent">
                <div className="mycourses-ul-list">
                  <div className="row couses-newrw-lists">
                    {!state.showQual && (
                      <>
                        {state.shortCourse.map((ele, i) => {
                            var icon = getSubjectStatus(ele?.icon);
                          return (<div className="col-md-4 col-lg-3 qualification-box" key={i} title={ele.course}>
                            <div className={"couses-renew-card home-bx-card qual-tab " + (ele?.subjectStatus=="Not Yet Published" ? " short-course-disabled" : "")}>
                              <Link to={`/shortCourse/${ele.id}`}>
                                {ele.commentCount != 0 && <div className="nav-tpFixed-comments" title='You have unread messages'>
                                  <span className="nav-fa-comments">
                                    <small className="notify-count">{ele.commentCount ? ele.commentCount : 0}</small>
                                    <i className="fal fa-comments"></i>
                                  </span>
                                </div>}

                                <div className="couses-renew-profile">
                                  <span className="icon_couses-hd" style={{ backgroundColor: stc(ele.course ? ele.course : "") }}>
                                    <i className="fal fa-award"></i>
                                  </span>
                                  <figure style={{ backgroundColor: stc(ele.course ? ele.course : "") }}>{createAbbreviation(ele.course)}</figure>
                                </div>
                                <div className="course-title-main">
                                  <h2 title={ele.course ? ele.course : ""}>{ele.course ? TrimText(ele.course, 50) : ""}</h2>
                                </div>
                                <div className="code_couses">
                                  <h6>{ele.code ? ele.code : ""}</h6>
                                  {/* <span title="Short Course">SHORT COURSE</span> */}
                                </div>
                                <div className="code_couses_ptg">
                                  <p>
                                    <span className="couse_i_in"><i className="fal fa-book"></i></span>
                                    {ele.type ? ele.type : ""}
                                  </p>
                                  <div className="d-flex align-items-center">
                                    {ele.type === "Self-Paced" ? (
                                        <p style={{ marginRight: "auto", fontSize: "12px" }}>
                                          <span className="couse_i_in">
                                            <i className="fal fa-clock"></i>
                                          </span>
                                          {ele?.subject_duration} months
                                        </p>
                                      ) : (
                                        <p style={{ marginRight: "auto", fontSize: "12px" }}>
                                          <span className="couse_i_in">
                                            <i className="fal fa-calendar-alt"></i>
                                          </span>
                                          {ele.start ? moment.unix(ele.start).local().format("D MMM YYYY") : ""} - {ele.end ? moment.unix(ele.end).local().format("D MMM YYYY") : ""}
                                        </p>
                                      )}
                                    <img  src={icon} alt="" width="15px" height="15px" />
                                    <p style={{fontSize : "12px"}}>{ele?.subjectStatus}</p>
                                  </div>
                                  
                                </div>
                                <div className="quali_couses_ptg mt-2">
                                  {(ele?.file && ele?.file !== "") && <button title="Download Timetable" className="btn download_btn" onClick={(e) => { e.preventDefault(); downloadFile(ele?.file.replace("/home/myaie/public_html","https://myaie.ac"), ele?.file.replace("/home/myaie/public_html","https://myaie.ac").split("/").reverse()[0]) }} >
                                    <i className="fal fa-arrow-down"></i> Download Timetable
                                  </button>}
                                </div>
                              </Link>
                            </div>
                          </div>)
                        })}


                      </>
                    )}

                    {state.showQual && (
                      <>
                        {state.qualifications.map((ele, ind) => {
                          return (
                            <div className="col-md-4 col-lg-3 qualification-box" key={ind} title={ele.course}>
                              <div className="couses-renew-card home-bx-card qualification-card">
                                <Link to={`/course/${ele.id}`}>
                                  {ele.commentCount != 0 && <div className="nav-tpFixed-comments" title='You have unread messages'>
                                    <span className="nav-fa-comments">
                                      <small className="notify-count">{ele.commentCount ? ele.commentCount < 10 ? ele.commentCount : "9+" : 0}</small>
                                      <i className="fal fa-comments"></i>
                                    </span>
                                  </div>}
                                  <div className="couses-renew-profile">
                                    <span className="icon_couses-hd">
                                      <i className="fal fa-file-certificate"></i>
                                    </span>
                                    <span className="md-img"> <img src={PuzzleImg} alt="React Logo" /></span>
                                    <span className="name-abbrev">{createAbbreviation(ele.course)}</span>
                                  </div>
                                  <div className="course-title-main">
                                    <h2 title={ele.course ? ele.course : ""}>{ele.course ? TrimText(ele.course, 50) : ""}</h2>
                                  </div>
                                  <div className="code_couses crt-sec">
                                    <span title="Certificate">CERTIFICATE</span>
                                  </div>
                                  <div className="quali_couses_ptg">
                                    <h6>{ele.type ? ele.type : ""}, Intake {ele.number ? ele.number : ""}, {ele.year ? ele.year : ""}</h6>
                                    {(ele.fileName != "" && ele.file != "") || (ele?.timeTable != null && ele?.timeTable != "" && ele?.timeTable) && <button title="Download Timetable" className="btn download_btn" onClick={(e) => { e.preventDefault(); downloadFile(ele.file, ele.fileName) }} >
                                      <i className="fal fa-arrow-down"></i> Download Timetable
                                    </button>}
                                  </div>
                                </Link>
                              </div>
                            </div>
                          )
                        })}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

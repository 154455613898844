import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ResponsibleDetails from "./blocks/ResponsibleDetails";
import PayerAboutDetails from "./blocks/PayerAboutDetails";
import PayerAddressDetails from "./blocks/PayerAddressDetails";
import PayerContactDetails from "./blocks/PayerContactDetails";
import { countryList, GetPayerDetailService, UpdateResponsible, UpdateUserPayerDetails } from "../../services/ProfileService";
import { setFile, setPayer, setUser } from "../../store/actions";
import PersonalDocuments from "./blocks/PersonalDocuments";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import Swal from "sweetalert2";
import { capitalize, genderOptions, languageListOptions, provinceOptions, raceOptions, titleOptions } from "../../utils/commonFunction";
import moment from "moment";
import PayerPersonalDocuments from "./blocks/PayerPersonalDocuments";
import { EMAIL_REGEX, MOBILE_REGEX } from "../../utils/Constants";

const SponsorPayer = () => {
  const payer = useSelector((state) => state.payer);
  const user = useSelector((state) => state.user);
  const file = useSelector((state) => state.file);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [dateDropdown, setDateDropdown] = useState({
    year: [],
    day: [],
    month: []
  });

  const handleDateDropdownOptions = () => {
    let yearOptions = [];
    let dayOptions = [];
    for (let index = 1900; index < moment().year(); index++) {
      yearOptions.push({ value: index, label: index });
    }

    for (let index = 1; index <= 31; index++) {
      dayOptions.push({ value: index, label: index });
    }

    let monthOptions = moment.months().map((month, index) => {
      return (
        { value: index + 1, label: month }
      );
    })
    setDateDropdown({
      ...dateDropdown,
      year: yearOptions,
      day: dayOptions,
      month: monthOptions
    });
  };

console.log("user" , user)

  useEffect(() => {
    handleDateDropdownOptions();
    countryList()
    .then((res) => {
      const respData = res.data.data;
      const countryOpt = respData.map((item) => {
        return (
          { value: item.value, label: item.value }
        );
      })
      setCountries([...countryOpt]);
    })
    .catch((error) => {
      setCountries([]);
    });
    GetPayerDetailService()
      .then((res) => {
        dispatch(setPayer(res.data[0]));
      })
      .catch((error) => { });
  }, []);

  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    title: Yup.string().required("Title is required"),
    number: Yup.string().required("Id Number is required"),
    bday_year: Yup.string().required("Year is required"),
    bday_month: Yup.string().required("Month is required"),
    bday_day: Yup.string().required("Day is required"),
    race: Yup.string().required("Race is required"),
    gender: Yup.string().required("Gender is required"),
    language: Yup.string().required("Language is required"),
    email: Yup.string().email("Invalid email address").required("Email is required").matches(EMAIL_REGEX, "Invalid email address"),
    mobile: Yup.string().matches(MOBILE_REGEX,"Phone number should be 0-9 of 10 digits with country code").required("Mobile is required"),
    phone: Yup.string().matches(MOBILE_REGEX,"Phone number should be 0-9 of 10 digits with country code"),
    a_phone: Yup.string().matches(MOBILE_REGEX,"Phone number should be 0-9 of 10 digits with country code"),
    ph_street: Yup.string().required("Street address is required"),
    ph_city: Yup.string().required("City is required"),
    ph_province: Yup.string().required("Province is required"),
    ph_country: Yup.string().required("Country is required"),
    ph_zip: Yup.string().required("Postal Code is required"),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: payer?.first_name ? payer?.first_name : "",
      second_name: payer?.second_name ? payer?.second_name : "",
      last_name: payer?.last_name ? payer?.last_name : "",
      title: payer?.title ? payer?.title : "",
      number: payer?.number ? payer?.number : "",
      bday_year: payer?.bday_year ? payer?.bday_year : "",
      bday_month: payer?.bday_month ? payer?.bday_month : "",
      bday_day: payer?.bday_day ? payer?.bday_day : "",
      race: payer?.race ? payer?.race : "",
      gender: payer?.gender ? payer?.gender : "",
      language: payer?.language ? payer?.language : "",

      email: payer?.email ? payer?.email : "",
      mobile: payer?.mobile ? payer?.mobile : "",
      phone: payer?.phone ? payer?.phone : "",
      a_phone: payer?.a_phone ? payer?.a_phone : "",

      ph_street: payer?.ph_street ? payer?.ph_street : "",
      ph_city: payer?.ph_city ? payer?.ph_city : "",
      ph_province: payer?.ph_province ? payer?.ph_province : "",
      ph_country: payer?.ph_country ? payer?.ph_country : "",
      ph_zip: payer?.ph_zip ? payer?.ph_zip : "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(values)
      setLoading(true);
      UpdateUserPayerDetails(values).then((res)=>{
        if(res.status == 200){
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          }).then((result) => {
            if (result.value) {
              dispatch(setPayer(res.data));              
            }
          });
        }
      }).catch((err)=>{
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data[0].message,
        });
      }).finally(()=>{
        setLoading(false);
      })
    }
  })

  const { values, errors, setFieldValue, touched, handleChange, handleBlur, handleSubmit } = formik;

  const handleChangeResponsible = () =>{
    const responsible = user?.payer_same == 0 ? 1 : 0;

    UpdateResponsible({ value: responsible })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Updated successfully",
        }).then((result) => {
          user.payer_same = responsible;
          dispatch(setUser(user));
          dispatch(setPayer(res.data.payer_details));
          dispatch(setFile(!file));
        });
      })
      .catch((error) => {});
  }

  return (
    <div className="card card-profile-info-card">
      <div className="d-md-flex">
        <div className="card-header mb-2 d-flex mr-2">
          About Sponsor / Account Payer
        </div>
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheck1"
            defaultChecked={user.payer_same}
            checked={user.payer_same == 1}
          onChange={(e) => handleChangeResponsible(e)}
          />
          <label className="custom-control-label" htmlFor="customCheck1">
            I am responsible for the payment of the account.
          </label>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row mt-2">
          <div className="col-md-3">
            <div className="form-group" title="First Name">
              <label>First Name*</label>
              <input
                type="text"
                className={
                  "form-control" +
                  (errors.first_name && touched.first_name
                    ? " is-invalid"
                    : "")
                }
                placeholder="First Name"
                value={user.payer_same==1 ? user.first_name : values.first_name}
                name="first_name"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={user.payer_same==1}
              />
              <p className="form-info-text"><i className="fal fa-info-circle "></i> As Per Your ID Document</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group" title="Second Name">
              <label>Second Name(Optional)</label>
              <input
                type="text"
                className={"form-control"}
                placeholder="Second Name"
                value={user.payer_same==1 ? user.second_name : values.second_name}
                name="second_name"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={user.payer_same==1}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group" title="Last Name">
              <label>Last Name *</label>
              <input
                type="text"
                className={
                  "form-control" +
                  (errors.last_name && touched.last_name
                    ? " is-invalid"
                    : "")
                }
                placeholder="Last Name"
                value={user.payer_same==1 ? user.last_name : values.last_name}
                name="last_name"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={user.payer_same==1}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group" title="Title">
              <label>Title *</label>
              {user.payer_same==0 ? 
              <div className="form-icon-group">
                <Select
                  className={
                    "form-control custom-select-box" +
                    (errors.title && touched.title
                      ? " is-invalid"
                      : "")
                  }
                  name="title"
                  value={titleOptions?.find(item => item.value == values.title)}
                  onChange={(value) => { setFieldValue("title", value.value) }}
                  onBlur={handleBlur}
                  maxMenuHeight={175}
                  placeholder={"Title"}
                  options={titleOptions}                  
                />
              </div>
              :<input
                type="text"
                className={"form-control"}
                placeholder="Title"
                value={titleOptions?.find(item => item.value === user.title)?.label}
                name="title"
                disabled={user.payer_same==1}
              />}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group" title="Gender">
              <label>Gender *</label>
              {user.payer_same==0 ? 
              <div className="form-icon-group">
                <Select
                  className={
                    "form-control custom-select-box" +
                    (errors.gender && touched.gender
                      ? " is-invalid"
                      : "")
                  }
                  name="gender"
                  value={genderOptions.find(item => (item.value).toLowerCase() == (values.gender).toLowerCase())}
                  onChange={(value) => { setFieldValue("gender", value.value) }}
                  onBlur={handleBlur}
                  maxMenuHeight={175}
                  placeholder={"Gender"}
                  options={genderOptions}
                />
              </div>
              :<input
                type="text"
                className={"form-control"}
                placeholder="Gender"
                value={genderOptions.find(item => item.value.toLowerCase() == user.gender.toLowerCase()).label}
                name="gender"
                disabled={user.payer_same==1}
              />}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group" title="ID/Passport Number">
              <label>ID/Passport Number *</label>
              <input
                type="text"
                className={
                  "form-control" +
                  (errors.number && touched.number
                    ? " is-invalid"
                    : "")
                }
                placeholder="ID/Passport Number"
                name="number"
                value={user.payer_same==1 ? user.number : values.number}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={user.payer_same==1}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group" title="Home Language">
              <label>Home Language *</label>
              {user.payer_same==0 ? 
              <div className="form-icon-group">
                <Select
                  className={
                    "form-control custom-select-box" +
                    (errors.language && touched.language
                      ? " is-invalid"
                      : "")
                  }
                  name="language"
                  value={languageListOptions.find(item => (item.value).toLowerCase() == (values.language).toLowerCase())}
                  onChange={(value) => { setFieldValue("language", value.value) }}
                  onBlur={handleBlur}
                  maxMenuHeight={175}
                  placeholder={"Language"}
                  options={languageListOptions}
                />
              </div>
              :<input
                type="text"
                className={"form-control"}
                placeholder="Language"
                value={languageListOptions.find(item => item.value.toLowerCase() == user.language.toLowerCase()).label}
                name="language"
                disabled={user.payer_same==1}
              />}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group" title="Race">
              <label>Race *</label>
              {user.payer_same==0 ? 
              <div className="form-icon-group">
                <Select
                  className={
                    "form-control custom-select-box" +
                    (errors.race && touched.race
                      ? " is-invalid"
                      : "")
                  }
                  name="race"
                  value={raceOptions.find(item => (item.value) == (values.race))}
                  onChange={(value) => { setFieldValue("race", value.value) }}
                  onBlur={handleBlur}
                  maxMenuHeight={175}
                  placeholder={"Race"}
                  options={raceOptions}
                />
              </div>
              :<input
                type="text"
                className={"form-control"}
                placeholder="Race"
                value={raceOptions.find(item => item.value.toLowerCase() == user.race.toLowerCase()).label}
                name="race"
                disabled={user.payer_same==1}
              />}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group date-form-group">
              <label>Date Of Birth *</label>
              <div className="row form-icon-group">
                <div className="col-3">
                  {user.payer_same==0 ? 
                  <Select
                    className={
                      "form-control custom-select-box" +
                      (errors.bday_day && touched.bday_day
                        ? " is-invalid"
                        : "")
                    }
                    name="bday_day"
                    value={dateDropdown.day.find(item => item.value == values.bday_day)}
                    onChange={(value) => { setFieldValue("bday_day", value.value) }}
                    onBlur={handleBlur}
                    maxMenuHeight={175}
                    placeholder={"Day"}
                    options={dateDropdown.day}
                  />
                  :<input
                    type="text"
                    className={"form-control"}
                    placeholder="Day"
                    value={user.bday_day}
                    name="bday_day"
                    disabled={user.payer_same==1}
                  />}
                  {errors.bday_day && touched.bday_day ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
                <div className="col-5">
                  {user.payer_same==0 ? 
                  <Select
                    className={
                      "form-control custom-select-box" +
                      (errors.bday_month && touched.bday_month
                        ? " is-invalid"
                        : "")
                    }
                    name="bday_month"
                    value={dateDropdown.month.find(item => item.value == values.bday_month)}
                    onChange={(value) => {
                      setFieldValue("bday_month", value.value)
                    }}
                    onBlur={handleBlur}
                    maxMenuHeight={175}
                    placeholder={"Month"}
                    options={dateDropdown.month}
                  />
                  :<input
                    type="text"
                    className={"form-control"}
                    placeholder="Month"
                    value={dateDropdown.month.length && dateDropdown.month.find(item => item.value == user.bday_month).label}
                    name="bday_month"
                    disabled={user.payer_same==1}
                  />}
                  {errors.bday_month && touched.bday_month ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
                <div className="col-4">
                  {user.payer_same==0 ? 
                  <Select
                    className={
                      "form-control custom-select-box" +
                      (errors.bday_year && touched.bday_year
                        ? " is-invalid"
                        : "")
                    }
                    name="bday_year"
                    value={dateDropdown.year.find(item => item.value == values.bday_year)}
                    onChange={(value) => {
                      setFieldValue("bday_year", value.value)
                    }}
                    onBlur={handleBlur}
                    maxMenuHeight={175}
                    placeholder={"Year"}
                    options={dateDropdown.year}
                  />
                  :<input
                    type="text"
                    className={"form-control"}
                    placeholder="Year"
                    value={user.bday_year}
                    name="bday_year"
                    disabled={user.payer_same==1}
                  />}
                  {errors.bday_year && touched.bday_year ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="card-header mb-4">Contact Information</div>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group" title="Email">
              <label>Email*</label>
              <input
                type="email"
                className={
                  "form-control" +
                  (errors.email && touched.email
                    ? " is-invalid"
                    : "")
                }
                name="email"
                title="Email"
                placeholder="Email"
                value={user.payer_same==1 ? user.email : values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={user.payer_same==1}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group" title="Mobile">
              <label>Mobile Number*</label>
              <input
                type="text"
                className={
                  "form-control" +
                  (errors.mobile && touched.mobile
                    ? " is-invalid"
                    : "")
                }
                name="mobile"
                title="Mobile"
                placeholder="Mobile"
                value={user.payer_same==1 ? user.mobile : values.mobile}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={user.payer_same==1}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group" title="Landline">
              <label>Landline </label>
              <input
                type="text"
                className={
                  "form-control" +
                  (errors.phone && touched.phone
                    ? " is-invalid"
                    : "")
                }
                name="phone"
                title="Landline"
                placeholder="Landline"
                value={user.payer_same==1 ? user.phone : values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={user.payer_same==1}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group" title="Alternative Contact Number">
              <label>Alternative Contact Number </label>
              <input
                type="text"
                className={
                  "form-control" +
                  (errors.a_phone && touched.a_phone
                    ? " is-invalid"
                    : "")
                }
                name="a_phone"
                title="Alternative Contact Number"
                placeholder="Alternative Contact Number"
                value={user.payer_same==1 ? user.a_phone : values.a_phone}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={user.payer_same==1}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="card-header mb-4">Address Information</div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group" title="Street Address">
              <label>Street Address *</label>
              <input
                type="text"
                className={
                  "form-control" +
                  (errors.ph_street && touched.ph_street
                    ? " is-invalid"
                    : "")
                }
                name="ph_street"
                placeholder="Street Address"
                value={user.payer_same==1 ? user.ph_street : values.ph_street}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={user.payer_same==1}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group" title="City">
              <label>City *</label>
              <input
                type="text"
                className={
                  "form-control" +
                  (errors.ph_city && touched.ph_city
                    ? " is-invalid"
                    : "")
                }
                name="ph_city"
                placeholder="City"
                value={user.payer_same==1 ? user.ph_city : values.ph_city}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={user.payer_same==1}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group" title="Postal Code">
              <label>Postal Code *</label>
              <input
                type="text"
                className={
                  "form-control" +
                  (errors.ph_zip && touched.ph_zip
                    ? " is-invalid"
                    : "")
                }
                name="ph_zip"
                value={user.payer_same==1 ? user.ph_zip : values.ph_zip}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={user.payer_same==1}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group" title="Province">
              <label>Province *</label>
              {user.payer_same==0 ? 
              <div className="form-icon-group">
                <Select
                  className={"form-control custom-select-box" + (errors.ph_province && touched.ph_province ? " is-invalid" : "")}
                  name="ph_province"
                  value={provinceOptions.find(item => item.value == values.ph_province)}
                  onChange={(value) => setFieldValue("ph_province", value.value)}
                  onBlur={handleBlur}
                  options={provinceOptions}
                  maxMenuHeight={175}
                  placeholder={"Province"}
                />
              </div>
              :<input
              type="text"
              className="form-control"
              placeholder="Province"
              value={provinceOptions?.find(item => item.value === user.ph_province)?.label}
              name="ph_province"
              disabled={user.payer_same === 1}
            />}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group" title="Country">
              <label>Country *</label>
              {user.payer_same==0 ? 
              <div className="form-icon-group">
                <Select
                  className={
                    "form-control custom-select-box" +
                    (errors.ph_country && touched.ph_country
                      ? " is-invalid"
                      : "")
                  }
                  name="ph_country"
                  value={countries.find(item => item.value == values.ph_country)}
                  onChange={(value) => setFieldValue("ph_country", value.value)}
                  onBlur={handleBlur}
                  maxMenuHeight={175}
                  placeholder={"Country"}
                  options={countries}
                />
              </div>
              :<input
                type="text"
                className={"form-control"}
                placeholder="Country"
                value={countries?.find(item => item.value == user.ph_country)?.label}
                name="ph_country"
                disabled={user.payer_same==1}
              />}
            </div>
          </div>
        </div>
        <hr />
        <div className="card-header mb-2">Your Personal Documents</div>
        <div className="row">
          <div className="col-md-12">
            <PayerPersonalDocuments 
              docType={payer.payer_same ? "personal_doc" : "personal_doc_payer"}
              heading={"Your Personal Documents"}
            />
          </div>              
        </div>
        <div className="form-group form-group-save-cancel mt-4">
          <button
            className="btn btn-save btn-success"
            type="submit"
            title="Save"
            disabled={!formik.dirty || loading || user.payer_same==1}
          >
            {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
            Save Changes
          </button>
          <button
            className="btn btn-close btn-danger"
            type="button"
            onClick={() => { formik.resetForm() }}
            title="Cancel"
          >
            <i className="fal fa-times"></i>
            Cancel
          </button>
        </div>
        {Object.keys(formik.errors).map((key) => (
          Array.isArray(formik.errors[key]) && Array.isArray(formik.touched[key]) ?
            <div className="invalid-feedback d-block">
              {formik.errors[key][0].subjectName}
            </div>
            : formik.touched[key] && formik.errors[key] ? (
              <div className="invalid-feedback d-block">
                {formik.errors[key]}
              </div>
            ) : null
        ))}
      </form>
    </div>
  );
};

export default SponsorPayer;

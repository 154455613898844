import React from 'react'

// Don't make any change this function
const UIRender = ({ className, text, type }) => {
    if (type == "tableIcon") {
        return <i className={"fal " + className}></i>
    }
    return {
        className: "fal " + className,
        text,
        html: (
            <span title={text}>
                {text !== "-" && <i className={"fal " + className}></i>}{text}
            </span>
        ),
    }
}

export const GlobalIconNames = (data, customIconName, customName) => {
    // use case name as data value if no case is created for that data then send customIconName and customName
    let value = data ? data.toString().toLowerCase().replaceAll("-", "").replaceAll(" ", "").trim() : data;
    switch (value) {
        case customName ? customName.toString().toLowerCase().replaceAll("-", "").replaceAll(" ", "").trim() : customName:
            return UIRender({ className: customIconName, text: customName, });

        // Don't Create new case Above this line

        case "details":
            return UIRender({ className: "fa-info-circle", text: "Details", });
        case "audit-trail":
            return UIRender({ className: "fa-history", text: "Audit Trails" });
        case "modules":
            return UIRender({ className: "fa-window", text: "Modules", });
        case "programmes":
            return UIRender({ className: "fa-laptop", text: "Programmes", });
        case "assignedprogrammes":
            return UIRender({ className: "fa-laptop", text: "Assigned Programmes", });
        case "articulations":
            return UIRender({ className: "fa-university", text: "Articulations", });
        case "audittrail":
            return UIRender({ className: "fa-history", text: "Audit Trails", });
        case "onlyiconaudittrail":
            return UIRender({ className: "fa-history", text: "Audit Trails", type: "tableIcon" });
        case "appeals":
            return UIRender({ className: "fa-gavel", text: "Appeals", });
        case "qualification":
            return UIRender({ className: "fa-chart-pie-alt", text: "Programme", });
        case "students":
            return UIRender({ className: "fa-users", text: "Students", });
        case "onlyiconstudents":
            return UIRender({ className: "fa-users", text: "Students", type: "tableIcon" });
        case "users":
            return UIRender({ className: "fa-users", text: "Users", });
        case "brandmanagement":
            return UIRender({ className: "fa-copyright", text: "Brand Management", });
        case "brand":
            return UIRender({ className: "fa-copyright", text: "Brand", });
        case "grademixdetails":
            return UIRender({ className: "fa-info-circle", text: "Details", });
        case "grademixgradebook":
            return UIRender({ className: "fa-bookmark", text: "Gradebook", });
        case "netsuitemanagement":
            return UIRender({ className: "fa-whistle", text: "NetSuite Management", });
        case "customerrecords":
            return UIRender({ className: "fa-address-book", text: "Customer Records", });
        case "studentmergeddata":
            return UIRender({ className: "fa-file-check", text: "Student Merged Data", });
        case "courses":
            return UIRender({ className: "fa-chalkboard", text: "Courses", });
        case "bookedcourses":
            return UIRender({ className: "fa-calendar-alt", text: "Booked Courses", });
        case "onlyiconcourses":
            return UIRender({ className: "fa-chalkboard", text: "Courses", type: "tableIcon" });
        case "notifications":
            return UIRender({ className: "fa-bell", text: "Notifications", });
        case "registration":
            return UIRender({ className: "fa-sign-in-alt", text: "All Registrations", });
        case "registrationsonly":
            return UIRender({ className: "fa-user-plus", text: "Registrations", });
        case "reregistrationsonly":
            return UIRender({ className: "fa-user-graduate", text: "Re-Registrations", });
        case "slpregistrationsonly":
            return UIRender({ className: "fa-laptop", text: "SLP Registrations", });
        case "ebooksexport":
            return UIRender({ className: "fa-book-alt", text: "E-Books Export", });
        case "programmereregistration":
            return UIRender({ className: "fa-cabinet-filing", text: "Programme Re-Registration", });
        case "studentregistration":
            return UIRender({ className: "fa-clipboard-list", text: "Registrations", });
        case "permissions":
            return UIRender({ className: "fa-exclamation-triangle", text: "Permission", });
        case "reset":
            return UIRender({ className: "fa-redo", text: "Reset", });
        case "email":
            return UIRender({ className: "fa-envelope", text: "Email", type: "tableIcon" });
        case "mobile":
            return UIRender({ className: "fa-mobile-android-alt", text: "mobile", type: "tableIcon" });
        case "contentassemblies":
            return UIRender({ className: "fa-info-circle", text: "Content Assemblies" });
        case "programmearticulation":
            return UIRender({ className: "fa-university", text: "Programme Articulation" });
        case "intakecopy":
            return UIRender({ className: "fa-copy", text: "Intake Copy" });
        case "quizzes":
            return UIRender({ className: "fa-file-check", text: "Quizzes" });
        case "quizmanagement":
            return UIRender({ className: "fa-file-edit", text: "Quiz Management" });
        case "questionbank":
            return UIRender({ className: "fa-file-spreadsheet", text: "Question Bank" });
        case "tickets":
            return UIRender({ className: "fa-ticket-alt", text: "Tickets" });
        case "onlyicontickets":
            return UIRender({ className: "fa-ticket-alt", text: "Tickets", type: "tableIcon" });
        case "ticketsettings":
            return UIRender({ className: "fa-users", text: "Ticket Settings" });
        case "general":
            return UIRender({ className: "fa-wrench", text: "General" });
        case "servermanagement":
            return UIRender({ className: "fa-server", text: "Server Management" });
        case "classmanagement":
            return UIRender({ className: "fa-users-class", text: "Class Management" });
        case "campusmanagement":
            return UIRender({ className: "fa-building", text: "Campus Management" });
        case "studenthublibrary":
            return UIRender({ className: "fa-info-circle", text: "Student Hub & Library" });
        case "resourcemanagement":
            return UIRender({ className: "fa-books", text: "Resource Management" });
        case "libraryborrowers":
            return UIRender({ className: "fa-book-reader", text: "Library Borrowers" });
        case "studenthubmanagement":
            return UIRender({ className: "fa-users-cog", text: "Student Hub Management" });
        case "livechat":
            return UIRender({ className: "fa-comments", text: "Live Chat" });
        case "banners":
            return UIRender({ className: "fa-pennant", text: "Banners" });
        case "bulknotificationsgroup":
            return UIRender({ className: "fa-bell", text: "Bulk Notifications" });
        case "bulknotifications":
            return UIRender({ className: "fa-exclamation-circle", text: "Bulk Notifications" });
        case "notificationlists":
            return UIRender({ className: "fa-envelope-square", text: "Sent" });
        case "pendingnotification":
            return UIRender({ className: "fa-comment-alt-dots", text: "Pending" });
        case "stoppednotifications":
            return UIRender({ className: "fa-times-hexagon", text: "Stopped" });
        case "notificationtemplates":
            return UIRender({ className: "fa-file-invoice", text: "Notification Templates" });
        case "featuresandbenefits":
            return UIRender({ className: "fa-ballot-check", text: "Features & Benefits" });
        case "openfolder":
            return UIRender({ className: "fa-folder-open", text: "Open", type: "tableIcon" });
        case "delete":
            return UIRender({ className: "fa-trash-alt", text: "Delete", type: "tableIcon" });
        case "filter":
            return UIRender({ className: "fa-filter", text: "Filter", type: "tableIcon" });
        case "excel":
            return UIRender({ className: "fa-file-excel icon", text: "Excel", type: "tableIcon" });
        case "csv":
            return UIRender({ className: "fa-file-csv icon", text: "csv", type: "tableIcon" });
        case "pdf":
            return UIRender({ className: "fa-file-pdf icon", text: "pdf", type: "tableIcon" });
        case "circle":
            return UIRender({ className: "fa-circle", text: "circle", type: "tableIcon" });
        case "important":
            return UIRender({ className: "fa-exclamation important-icon", text: "Important", type: "tableIcon" });
        case "downarrow":
            return UIRender({ className: "fa-angle-down", text: "Down Arrow", type: "tableIcon" });
        case "leftarrow":
            return UIRender({ className: "fa-angle-left", text: "Left Arrow", type: "tableIcon" });
        case "studentno":
            return UIRender({ className: "fa-id-badge", text: "Student No", type: "tableIcon" });
        case "studentview":
            return UIRender({ className: "fa-eye", text: "Student View", type: "Student view" });
        case "studentprofile":
            return UIRender({ className: "fa-user", text: "Student Profile", type: "Student Profile" });
        case "exam":
            return UIRender({ className: "fal fa-ballot", text: "Exams", type: "External Exam" });
        case "externalexam":
            return UIRender({ className: "fal fa-book-user", text: "External Exam Registrations", type: "External Exam" });
        case "examperiod":
            return UIRender({ className: "fal fa-calendar-day", text: "Exam Period", type: "External Exam" });
        case "examcentre":
            return UIRender({ className: "fal fa-university", text: "Exam Centres", type: "External Exam" });
        case "examsetting":
            return UIRender({ className: "fal fa-cog", text: "Exam Settings", type: "External Exam" });
        case "gradinglettertemplates":
            return UIRender({ className: "fa-badge-check", text: "Grading Letter Template" });
        case "certificatetemplate":
            return UIRender({ className: "fa-file-certificate", text: "Certificate Template" });
        case "back":
            return UIRender({ className: "fa-chevron-left" })
        case "history":
            return UIRender({ className: "fa-history" })
        case "bin":
            return UIRender({ className: "fa-trash-alt" })
        case "calendar":
            return UIRender({ className: "fa-calendar-days" });
        case "tools":
            return UIRender({ className: "fa-tools" });
        case "compass":
            return UIRender({ className: "fa-compass" })
        case "sitemap":
            return UIRender({ className: "fa-sitemap" })
        case "envelope":
            return UIRender({ className: "fa-envelope" })
        case "file":
            return UIRender({ className: "fa-file" })
        case "graduationcap":
            return UIRender({ className: "fa-graduation-cap" })
        case "school":
            return UIRender({ className: "fa-school" })
        case "layerplus":
            return UIRender({ className: "fa-layer-plus" })
        case "window":
            return UIRender({ className: "fa-window" })
        case "books":
            return UIRender({ className: "fa-books" })
        case "globe":
            return UIRender({ className: "fa-globe" })
            return UIRender({className: "fa-trash-alt"}) 
        case "shortcourse":
            return UIRender({className: "fa-calendar-alt", text: "Short Course"})
        case "Course Dates":
            return UIRender({ className: "fa-calendar-alt", text: "Course Dates" })
        case "programmepages":
            return UIRender({ className: "fa-chalkboard-alt", text: "Programme Pages" })
        case "shortcoursepages":
            return UIRender({ className: "fa-laptop-alt", text: "Short Course Pages" })
        case "content":
            return UIRender({ className: "fa-newspaper", text: "Content" })
        case "pagecustomisation":
            return UIRender({ className: "fa-shapes", text: "Page Customisation" })
        case "programmedates":
            return UIRender({ className: "fa-calendar-alt", text: "Programme Dates" })                           
        case "course_dates":
            return UIRender({ className: "fa-calendar-alt", text: "Course Dates" })                           
        case "software":
            return UIRender({ className: "fa-solid fa-code", text: "Software Used" })                           
        default:
            return UIRender({ className: "", text: "-", });
    }
}
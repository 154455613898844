import React, { useState } from 'react';
import ArticulationAvatar from './ArticulationAvatar';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Open from "../../assets/images/Open.png";
import { GetQualificationsList, GetReRegNotSubmitReasonsList, articulationRegistrationRemindMeLater, submitArticulationRegistration } from '../../services/ReRegistrationService';
import Swal from 'sweetalert2';
import Select from "react-select";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setReRegistrationsReload } from '../../store/actions';

function Articulation({ ownArticulateData, data, articulation, update, setUpdate }) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isRemindMeLoading, setIsRemindMeLoading] = useState(false);
    const [reasonDropdownData, setReasonDropdownData] = useState([]);
    const [disableDeclineForm, setDisableDeclineForm] = useState(false);
    const [selectedArti, setSelectedArti] = useState([]);
    const [schoolsList, setSchoolsList] = useState([]);
    const [learningMaterialsList, setLearningMaterialsList] = useState([]);
    const [qualificationsList, setQualificationsList] = useState([]);
    const reload = useSelector(state => state.registrations.reload);

    useEffect(() => {
        setSchoolsList(ownArticulateData.school_type_list)
        setLearningMaterialsList(ownArticulateData.learning_method_list)
        GetReRegNotSubmitReasonsList()
            .then(res => {
                setReasonDropdownData(res?.data?.data.map(item => ({ value: item?.id.toString(), label: item?.reason })));
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    const initialValue = {
        selectedArticulations: [],
        isOwnArticulate: false,
        school: "",
        learningMethod: "",
        qualification: "",
    }
    const ReRegLaunchSchema = Yup.object({
        selectedArticulations: Yup.array().when("isOwnArticulate", {
            is: false,
            then: Yup.array().min(1, "Please select at least one programme"),
        }),
        school: Yup.string().when("isOwnArticulate", {
            is: true,
            then: Yup.string().required("Please select school"),
        }),
        learningMethod: Yup.string().when(["isOwnArticulate","school"], {
            is: (isOwnArticulate,school)=>{
                return (isOwnArticulate === true && school !== "");
            },
            then: Yup.string().required("Please select learning method"),
        }),
        qualification: Yup.string().when(["isOwnArticulate","school","learningMethod"], {
            is: (isOwnArticulate,school,learningMethod)=>{
                return (isOwnArticulate === true && school !== "" && learningMethod !== "");
            },
            then: Yup.string().required("Please select qualification"),
        }),
    })
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValue,
        validationSchema: ReRegLaunchSchema,
        onSubmit: (values) => {
            setIsLoading(true);
            const params = {
                articulation_id: articulation?.id,
                articulation_qualification: [...values?.selectedArticulations, values?.qualification],
            };
            submitArticulationRegistration(params).then(res=>{
                formik.resetForm();
                setIsLoading(false);
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Programme articulation set successfully",
                }).then((result)=>{
                    if(result.isConfirmed){
                        setUpdate(!update);
                        dispatch(setReRegistrationsReload(!reload));
                    }
                });
            }).catch(error=>{
                console.error(error);
                setIsLoading(false);
            });
        }
    });

    // handle remind me later
    const handleRemindMeLater = () => {
        setIsRemindMeLoading(true);
        let params = { articulation_id: articulation?.id, remind: 1 };
        articulationRegistrationRemindMeLater(params).then(res => {
            formik.resetForm();
            setIsRemindMeLoading(false);
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Remind me later set successfully",
            }).then((result) => {
                if (result.isConfirmed) {
                    setUpdate(!update);
                    dispatch(setReRegistrationsReload(!reload));
                }
            });
        }).catch(error => {
            console.error(error);
            setIsRemindMeLoading(false);
        });
    }

    // decline form 
    const initialValueDecline = {
        reasonType: "",
        reason: "",
    }
    const ReRegLaunchSchemaDecline = Yup.object({
        reasonType: Yup.string().required("Please select any reason type"),
        reason: Yup.string().when("reasonType", {
            is: (reasonType)=>reasonType === "8" || reasonType === "4",
            then: Yup.string().required("Reason is required"),
        }),
    })
    const formikDecline = useFormik({
        enableReinitialize: true,
        initialValues: initialValueDecline,
        validationSchema: ReRegLaunchSchemaDecline,
        onSubmit: (values) => {
            setIsLoading(true);
            const params = {
                not_submit_reason: values?.reasonType,
                not_submit_reason_description: values?.reason,
                submit_reason: 1,
                articulation_id: articulation?.id,
            };
            articulationRegistrationRemindMeLater(params).then(res => {
                formik.resetForm();
                setIsLoading(false);
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Declined successfully ",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setUpdate(!update);
                        dispatch(setReRegistrationsReload(!reload));
                    }
                });
            }).catch(error => {
                console.error(error);
                setIsLoading(false);
            });
        }
    });

    const { handleSubmit, errors, touched, setFieldValue, values, handleBlur } = formik;

    const renderLearningMethod = (type) => {
        switch (type ? type.toString().replace("-", "").toLowerCase() : "") {
            case "full":
                return "Full-Time, Hybrid";
            case "fulltime":
                return "Full-Time, Hybrid";
            case "part":
                return "Part-Time, Online";
            case "parttime":
                return "Part-Time, Online";
            case "fulltimecontact":
                return "Full Time, Hybrid";
            case "parttimeonline":
                return "Part-Time, Online";
            case "cores":
                return "Part Time, Online";
            case "fulltimeonline":
                return "Full Time, Online";
            case "selfplaced":
                return "Self-Paced";
            default:
                return "-";
        }
    }

    useEffect(()=>{
        if(values?.school && values?.learningMethod){
            GetQualificationsList({school: values?.school, learningMethod: values?.learningMethod, uniqueDataKey: data.map(key=>key?.id)})
            .then(res => {
                // console.log("res::::::",res?.data);
                setQualificationsList(res?.data?.data.map(list=>({ value: list?.id, label: list?.course })));
            })
            .catch(error => {
                console.error(error);
            });
        }
    },[values?.school, values?.learningMethod])

    return (
        <>
            <div className='launch__form'>
                <div className='card card-profile-info-card mb-4'>
                    <div className='card-body re-reg-card'>
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-4 articulation__col">
                                <figure>
                                    <ArticulationAvatar />
                                </figure>
                            </div>
                            {!disableDeclineForm && <div className="col-md-7">
                                <div className='articulation-message'>
                                <h5>Congratulations - we noticed you're nearing the end of your studies!<span>*</span></h5>
                                </div>
                                <h6>Should you be interested in furthering your studies, choose the programme you're interested in and we'll contact you</h6>
                                <form className='d-flex flex-column mt-3' noValidate onSubmit={handleSubmit}>
                                    <div className='col-12'>
                                        <div className="row">
                                            {data?.map((items, index) => (
                                                <div className="col-md-6 pl-0 pb-3" key={index}>
                                                    <div className={'d-flex flex-column articulation__programme__box' + (selectedArti.includes(items?.id) ? " checked" : "")}>
                                                        <div className='d-flex justify-content-between align-items-baseline'>
                                                            <p>{items?.course}</p>
                                                            <div class="custom-control custom-checkbox ml-2">
                                                                <input
                                                                    type='checkbox'
                                                                    className='custom-control-input'
                                                                    checked={selectedArti.includes(items?.id) ? true : false}
                                                                    onChange={(e) => {
                                                                        let selected = selectedArti;
                                                                        // console.log("event__________", e.target.checked, "\nsele", selected);
                                                                        if (!e.target.checked) {
                                                                            const selectedInd = selected.findIndex(val => val === items?.id);
                                                                            if (selectedInd !== -1) {
                                                                                selected.splice(selectedInd, 1);
                                                                                setFieldValue("selectedArticulations", selected);
                                                                                setSelectedArti(selected);
                                                                            }
                                                                        } else {
                                                                            selected.push(items?.id);
                                                                            setFieldValue("selectedArticulations", selected);
                                                                            setSelectedArti(selected);
                                                                        }
                                                                    }}
                                                                />
                                                                <label class="custom-control-label"></label>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <span><i className="fal fa-list"></i> Intake {items?.number},{" "}{items?.year}</span>
                                                            <span><i className='fal fa-calendar-alt'></i> {moment.unix(items?.start).format("DD MMM")} to {moment.unix(items?.end).format("DD MMM 'YY")}</span>
                                                            {/* <span><img src={Open} alt="" /> OPEN</span> */}
                                                            <span><i className='fal fa-book'></i> {renderLearningMethod(items?.type)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='col-12 own__articulate'>
                                        <div className='row py-3'>
                                            <div className='col-12 pl-0'>
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        id="own__articulate"
                                                        type='checkbox'
                                                        className='custom-control-input'
                                                        onChange={(e) => { setFieldValue("isOwnArticulate", e.target.checked); }}
                                                    />
                                                    <label htmlFor='own__articulate' className="custom-control-label pl-1"><span className='d-inline-flex'>I would like to articulate to an option that is not on this list</span></label>
                                                </div>
                                            </div>
                                            {values?.isOwnArticulate ? <div className='col-12 pt-2 pl-0'>
                                                <div className='row'>
                                                    <div className='col-lg-4 col-12 pt-0 pr-3 mb-3 mb-lg-0'>
                                                        <div className="form-icon-group">
                                                            <Select
                                                                className={"form-control custom-select-box" + (errors.school && touched.school ? " is-invalid" : "")
                                                                }
                                                                name="school"
                                                                value={schoolsList.filter(item => item.value === values.school)}
                                                                onChange={(value) => { setFieldValue("school", value.value) }}
                                                                onBlur={handleBlur}
                                                                maxMenuHeight={175}
                                                                placeholder="Select School"
                                                                options={schoolsList}
                                                            />
                                                        </div>
                                                    </div>
                                                    {values.school && <div className='col-lg-4 col-12 pt-0 pr-3 mb-3 mb-lg-0'>
                                                        <div className="form-icon-group">
                                                            <Select
                                                                className={"form-control custom-select-box" + (errors.learningMethod && touched.learningMethod ? " is-invalid" : "")
                                                                }
                                                                name="learningMethod"
                                                                value={learningMaterialsList.filter(item => item.value === values.learningMethod)}
                                                                onChange={(value) => { setFieldValue("learningMethod", value.value) }}
                                                                onBlur={handleBlur}
                                                                maxMenuHeight={175}
                                                                placeholder="Select Learning Method"
                                                                options={learningMaterialsList}
                                                            />
                                                        </div>
                                                    </div>}
                                                    {(values.school && values.learningMethod) && <div className='col-lg-4 col-12 pt-0 pr-3'>
                                                        <div className="form-icon-group">
                                                            <Select
                                                                className={"form-control custom-select-box" + (errors.qualification && touched.qualification ? " is-invalid" : "")
                                                                }
                                                                name="qualification"
                                                                value={qualificationsList.filter(item => item.value === values.qualification)}
                                                                onChange={(value) => { setFieldValue("qualification", value.value) }}
                                                                onBlur={handleBlur}
                                                                maxMenuHeight={175}
                                                                placeholder="Select Qualification"
                                                                options={qualificationsList}
                                                            />
                                                        </div>
                                                    </div>}
                                                </div>
                                            </div>
                                            : null}
                                        </div>
                                    </div>
                                    {/* display form errors */}
                                    {(Object.keys(errors).length && Object.keys(touched).length) ? Object.keys(errors).map(item => {
                                        if (item === "learningMethod" && values?.school !== "") {
                                            return <span className="text-danger mt-1 mb-0" key={item}>{errors[item]}</span>
                                        } else if (item === "qualification" && values?.school !== "" && values?.learningMethod !== "") {
                                            return <span className="text-danger mt-1 mb-0" key={item}>{errors[item]}</span>
                                        } else if (item !== "learningMethod" && item !== "qualification") {
                                            return <span className="text-danger mt-1 mb-0" key={item}>{errors[item]}</span>
                                        }
                                    }) : ""}
                                    <div className='action-button-art mt-3'>
                                        <button type="submit" className='btn btn-primary mr-2' disabled={isLoading}>{isLoading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-check"></i>} Submit</button>
                                        <button type="button" className='btn btn-danger mr-2' onClick={() => { setDisableDeclineForm(true); formikDecline.resetForm(); setSelectedArti([]); }}><i class="fal fa-times"></i> Decline</button>
                                        {(!articulation?.hard_launch_date || moment.unix(articulation?.hard_launch_date).format("YY-MM-DD") > moment().format("YY-MM-DD")) &&
                                            <button type="button" className='btn btn-primary additional-btn' onClick={handleRemindMeLater} disabled={isRemindMeLoading}>
                                                {isRemindMeLoading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-bell-slash"></i>}
                                                Remind Me Later
                                            </button>
                                        }
                                    </div>
                                    <div className='articulation-btn-message'>
                                        <i className='fal fa-info-circle'/> 
                                        <p>Dependent on minimum qualification entry requirements</p> 
                                    </div>
                                </form>
                            </div>}
                            {/* Decline form */}
                            {disableDeclineForm && <div className="col-md-7">
                                <h5>Please indicate your reason for not choosing to progress your studies</h5>
                                <h6>Please indicate your reason for declining</h6>
                                <form className='d-flex flex-column mt-3' noValidate onSubmit={formikDecline.handleSubmit}>
                                    <div className="form-icon-group mb-2">
                                        <Select
                                            className={"form-control custom-select-box" + (formikDecline.errors.reasonType && formikDecline.touched.reasonType ? " is-invalid" : "")
                                            }
                                            name="reasonType"
                                            value={reasonDropdownData.filter(item => item.value === formikDecline.values.reasonType)}
                                            onChange={(value) => { formikDecline.setFieldValue("reasonType", value.value) }}
                                            onBlur={formikDecline.handleBlur}
                                            maxMenuHeight={175}
                                            placeholder="Select"
                                            options={reasonDropdownData}
                                        />
                                    </div>
                                    {(formikDecline.values.reasonType === "8" || formikDecline.values.reasonType === "4") && <div className="form-group-blk mb-2">
                                        <label>{formikDecline.values.reasonType === "8" ? "Reason *" : "What are you looking to study *"}</label>
                                        <textarea
                                            rows="2"
                                            name="reason"
                                            value={formikDecline.values.reason}
                                            onChange={(e) => formikDecline.setFieldValue("reason", e.target.value)}
                                            className={"form-control" + (formikDecline.errors.reason && formikDecline.touched.reason ? " is-invalid" : "")}
                                            placeholder={formikDecline.values.reasonType === "8" ? "Explain your other reason why you chose not to re-register." : "Explain what it is you are looking to study"}
                                        ></textarea>
                                    </div>}
                                    {/* display form errors */}
                                    {(Object.keys(formikDecline.errors).length && Object.keys(formikDecline.touched).length) ? Object.keys(formikDecline.errors).map(item => {
                                        return <span className="text-danger mt-1 mb-0" key={item}>{formikDecline.errors[item]}</span>
                                    }) : ""}
                                    <div className='d-flex align-items-center mt-3'>
                                        <button type="submit" className='btn btn-primary' disabled={isLoading}>{isLoading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-check"></i>} Submit</button>
                                        <button type="button" className='btn btn-primary additional-btn ml-2' onClick={() => { setDisableDeclineForm(false); formik.resetForm() }}><i className="fal fa-chevron-left"></i> Go Back</button>
                                    </div>
                                </form>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Articulation
import React, { useEffect, useState, useMemo, useRef } from "react";
import $ from "jquery";
import DataTable, { createTheme } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useRouteMatch } from "react-router-dom";
import Countdown from "react-countdown";
import AssignmentStatus from "../course/AssignmentStatus";
import AssignmentType from "../course/AssignmentType";

import { GetSubjectAssignments, getSubjectAssignmentsByFilter, GetAssignmentDetailByStatus } from "../../services/QualificationService";
import moment from "moment-timezone";
import Str from "../common/Str";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";
import { useSelector } from "react-redux";
import { twoDecimalRound } from "../../utils/commonFunction";

// moment.tz.setDefault("Africa/Johannesburg");



const ShowDuration = ({ data }) => (
  <div class="assessment-06"><p>{data.timeHour}h:{data.timeMin}m</p></div>
);

const ShowResult = ({ data }) => (
  data.grade == 1 ? (
    data.assessmentMarkPercentage == 0 ?
    (<div class="assessment-07">
      <div class="as-red-bg as-widget" data-toggle="tooltip" title={'Incomplete'} >
      <p className="oneline-ellipsis">
      <i class="fas fa-circle"></i>Incomplete
      </p>
      </div>
      </div>) :
      data.assessmentMarkPercentage > 49 ?
      (<div class="assessment-07">
        <div class="as-green-bg as-widget" data-toggle="tooltip" title={'Competent'} >
        <p className="oneline-ellipsis">
        <i class="fas fa-circle"></i>Competent
        </p>
        </div>
        </div>) :
        (<div class="assessment-07">
          <div class="as-orange-bg as-widget" data-toggle="tooltip" title={'Not Yet Competent'} >
          <p className="oneline-ellipsis">
          <i class="fas fa-circle"></i>Not Yet Competent
          </p>
          </div>
          </div>)
          
          // ) : (`${Number(data.assessmentMarkPercentage).toFixed(2)}%`)
        ) : (data?.grade == 3 ? `${data?.assCalculateGradeStatus?.gradeText != null ? data?.assCalculateGradeStatus?.gradeText : 0} / ${data?.subAssTotMark != null ? data?.subAssTotMark : 0}` : (data?.grade == 2 ? data?.assCalculateGradeStatus?.gradeText  : (`${twoDecimalRound(data?.assCalculateGradeStatus?.grade)}%`)))
      );
      
      const SubjectAssignmentsLatest = ({ subjectId, parentCall, shortCourse, subjectContent }) => {
        const [loading, setLoading] = useState(false);
        const [assignments, setAssignments] = useState([]);
        const [noDataComponent, setNoDataComponent] = useState([]);
        const [renderTable, setRenderTable] = useState(false);
        const [seeMore, setSeeMore] = useState([]);
        
        const selectorState = useSelector((state) => state);
        const { user, isWithHeld } = selectorState
        const isWithHeldStatus = localStorage.getItem("resultWithHeld");
        const AppealButton = ({ appeal_button, row_id }) => {
          switch (appeal_button) {
            case "appeal":
            return (
              <>
              <button
              title="Appeal"
              onClick={() => viewMe(row_id, "view_appeal")}
              className="btn rounded-circle btn-danger appeal-icon-circle"
              >
              <i class="fal fa-book-reader"></i>
              {/* <i className="fal fa-star"></i> Appeal */}
              </button>
              </>
            );
            break;
            case "appeal_view":
            return (
              <>
              <button
              title="View Appeal"
              onClick={() => viewMe(row_id, "view_appeal")}
              className="btn rounded-circle btn-warning"
              >
              <i class="fal fa-book-reader"></i>
              {/* <i className="fal fa-star"></i> View Appeal */}
              </button>
              </>
            );
            break;
            case "appeal_submit":
            return (
              <>
              <button
              title="Appeal Submitted"
              onClick={() => viewMe(row_id, "view_appeal")}
              className="btn rounded-circle btn-gray"
              disabled
              >
              <i class="fal fa-book-reader"></i>
              {/* <i className="fa fa-star"></i> Appeal Submitted */}
              </button>
              </>
            );
            break;
            default:
            return (
              <>
              <button
              title="No appeal options"
              onClick={() => { }}
              className="btn rounded-circle btn-gray"
              disabled
              >
              <i class="fal fa-book-reader"></i>
              </button>
              </>
            );
            break;
          }
        };
        
        const handleSeeMore = (row) => {
          let tempSeeMore = seeMore
          if (seeMore.includes(row.id)) {
            tempSeeMore.splice(tempSeeMore.indexOf(row.id), 1)
          } else {
            tempSeeMore.push(row.id)
          }
          setSeeMore([...tempSeeMore])
        }
        
        const columns = useMemo(() => [
          {
            name: "Type",
            selector: "ass_type",
            sortable: true,
            // minWidth: "130px",
            // maxWidth: "130px",
            cell: (row) => <AssignmentType ass_type={row.ass_type} />,
          },
          {
            name: "Name",
            selector: "name",
            wrap: true,
            sortable: true,
            // minWidth: "80px",
            // maxWidth: "180px",
            cell: (row) => (
              <div data-toggle="tooltip" title={row.name} className="assessment-02">
              <p onClick={() => viewMe(row.id, "view")} class="info-text">
              <a className="curser overflow-ellipsis2 plink">{row.name}</a>
              </p>
              </div>
            ),
          },
          {
            name: "Status",
            sortable: true,
            selector: "status",
            // minWidth: "170px",
            // maxWidth: "180px",
            /*center: true,*/
            cell: (row) => <AssignmentStatus status={row.status} />,
            
          },
          {
            name: <div class="thead-conditions">Conditions <i data-toggle="tooltip" title="This column communicates the nature of an exam/Assessment and the conditions associated with it" class="fal fa-info-circle"></i></div>,
            selector: "conditions",
            // minWidth: "180px",
            cell: (row) => (
              <div class="table-exam-act">
              <span class="ts-info-act">
              {row.camera ? <i data-toggle="tooltip" title="This exam requires your computer/Laptop to have an operational webcam" class="fal fa-webcam active"></i> : null}
              {row.audio ? <i data-toggle="tooltip" title="This exam requires your computer/Laptop to have an operational microphone" class="fal fa-microphone"></i> : null}
              {row.screen_share ? <i data-toggle="tooltip" title="This exam will record the contents of your screen, please have one screen and disconnect secondary screens" class="fal fa-desktop"></i> : null}
              {row.timeLimitActive ? <i data-toggle="tooltip" title="This is a timed exam" class="fal fa-clock"></i> : null}
              {/* <button type="button" class="btn btn-secondary" data-toggle="tooltip" data-placement="right" title={(row.camera || row.audio || row.screen_share) ? "This assignment has conditions" : "This exam will have no conditions"}><i class="fal fa-info-circle"></i></button> */}
              </span>
              </div>
            ),
            // button: true,
          },
          {
            name: "Open Date",
            selector: "open_date_time",
            sortable: true,
            omit : shortCourse,
            // minWidth: "160px",
            // maxWidth: "170px",
            cell: (row) => (
              <div class="assessment-04 dateTime">
              <p className="right-space">{moment.unix(row.open_date_time).format("D MMM 'YYYY")}</p><p>{moment.unix(row.open_date_time).format("HH:mm")}</p>
              {/* <p className="right-space">{row?.formatted_open_date_time ? row?.formatted_open_date_time : "-"}</p> */}
              </div>
            ),
          },
          {
            name: "Close Date",
            sortable: true,
            selector: "close_date_time",
            wrap: true,
            omit : shortCourse,
            // minWidth: "160px",
            // maxWidth: "170px",
            cell: (row) => (
              <div class="assessment-05 dateTime">
              <p className="right-space">{moment.unix(row.close_date_time).format("DD MMM 'YYYY")}</p><p>{moment.unix(row.close_date_time).format("HH:mm")}</p>
              {/* <p className="right-space">{row?.formatted_close_date_time ? row?.formatted_close_date_time : "-"}</p> */}
              </div>
            ),
          },
          {
            name: "Duration",
            selector: "timeHour",
            // minWidth: "80px",
            // maxWidth: "100px",
            sortable: true,
            cell: (row) =>
              row.timeLimitActive == 1 ? <ShowDuration data={row} /> : "N/A",
          },
          {
            name: "Result",
            selector: "grade",
            sortable: false,
            // minWidth: "140px",
            // maxWidth: "100%",
            cell: (row) => {
              return (row.ass_type != "Survey" ?
                (row.status === "Closed-Submitted" ? "-" 
                  : (row.status == "Closed-Marked" || row.status == "Closed Not-Submitted")  && row.notify_assessed == 1 
                ? ((subjectContent?.grading_standard == 2) ? (<>
                  {row?.grademixCulc?.length > 0 && row?.grademixCulc[0].grade == 'witheld' 
                    ? <span title={'Your marks are being withheld due to outstanding balances on your account, you will have limited access to the portal. Please contact the finance department by logging a ticket'} className="witheld-status">WITHHELD</span> 
                    :
                    (row?.grademixCulc || []).map((item, index) => {
                      if (index > 2 && !seeMore.includes(row.id)) {
                        return ""
                      }
                      return <div>{item.sor_name}: <strong>{item?.grading_type==3 ? `${item?.gradeText}/${item?.ass_total_mark}` : item.gradeText}</strong></div>
                    })}
                    {
                      row?.grademixCulc?.length && (row?.grademixCulc?.length > 3) ?
                      <span className="table-see-more" onClick={() => handleSeeMore(row)}>See 
                      { seeMore.includes(row.id) 
                        ? "Less" 
                        : "More"}...</span> 
                        : "" 
                      }
                      </>) : row?.assCalculateGradeStatus?.gradeText == 'witheld' 
                      ? <span title={'Your marks are being withheld due to outstanding balances on your account, you will have limited access to the portal. Please contact the finance department by logging a ticket'} className="witheld-status">WITHHELD</span> 
                      : <ShowResult data={row} />) 
                      :  "-")
                      : "-")
                    }
                  },
                  
                  {
                    name: "Action",
                    // minWidth: "120px",
                    cell: (row) => (
                      <div class="assessment-08 d-flex">
                      <div class="as-buttons">
                      <button
                      title="Open"
                      class="btn btn-primary rounded-circle"
                      onClick={() => viewMe(row.id, "view")}
                      >
                      <i class="fal fa-folder-open"></i>
                      </button>
                      </div>
                      {shortCourse ? null : <div class="as-buttons">
                        <AppealButton appeal_button={row.appeal_button} row_id={row.id} />
                        </div>}
                        
                        </div>
                      ),
                      // button: true,
                    },
                  ]);
                  
                  const [status, setStatus] = useState([])
                  const [searchStatusCheck, setSearchStatusCheck] = useState({
                    "action8": false,
                    "action9": false,
                    "action10": false,
                    "action11": false,
                    "action12": false,
                    "action13": false,
                    "action14": false,
                  })
                  const [searchType, setSearchType] = useState([])
                  const [searchTypeCheck, setSearchTypeCheck] = useState({
                    "action1": false,
                    "action2": false,
                    "action3": false,
                    "action4": false,
                    "action5": false,
                    "action6": false,
                    "action7": false,
                    "action9": false,
                    "action10": false,
                    "action15": false,
                    "action16": false,
                    "action17": false,
                    "action18": false,
                    "action19": false,
                    "action20": false
                  })
                  const [search, setSearch] = useState("");
                  
                  const dataToRender = () => {
                    let updatedData = []
                    let allData = assignments
                    if (search.length) {
                      let tempName = allData.filter(item => {
                        let includes = item.name.toLowerCase().includes(search.toLowerCase())
                        if (includes) {
                          return includes
                        } else return null
                      })
                      let tempType = allData.filter(item => {
                        let includes = item.ass_type && item.ass_type.toLowerCase().includes(search.toLowerCase())
                        if (includes) {
                          return includes
                        } else return null
                      })
                      let tempStatus = allData.filter(item => {
                        let includes = item.status && item.status.toLowerCase().includes(search.toLowerCase())
                        if (includes) {
                          return includes
                        } else return null
                      })
                      let tempOpenDate = allData.filter(item => {
                        let includes = item.open_date_time && (moment.unix(item.open_date_time).format("D MMM 'YY HH:mm a").toLowerCase().includes(search.toLowerCase()))
                        if (includes) {
                          return includes
                        } else return null
                      })
                      let tempCloseDate = allData.filter(item => {
                        let includes = item.close_date_time && (moment.unix(item.close_date_time).format("D MMM 'YY HH:mm a").toLowerCase().includes(search.toLowerCase()))
                        if (includes) {
                          return includes
                        } else return null
                      })
                      
                      let data = [...tempName, ...tempType, ...tempStatus, ...tempOpenDate, ...tempCloseDate]
                      let unique = [...new Set(data)]
                      // console.log('the values of inclues and statusSearch is ', tempName, tempType, tempStatus)
                      
                      updatedData = unique
                    } else {
                      updatedData = allData
                    }
                    
                    if (searchType.length) {
                      let tempStatus = updatedData
                      let tempSearch = tempStatus.filter(item => {
                        let tempItem = item.ass_type ? item.ass_type.toLowerCase() : ''
                        var tempItemNew = tempItem ? tempItem.replace(/-/g, ' ') : tempItem;
                        const startsWith = searchType.find(function (post, index) {
                          let tempPost = post ? post.toLowerCase() : ''
                          var tempPostNew = tempPost ? tempPost.replace(/-/g, ' ') : tempPost;
                          if (tempPostNew == tempItemNew)
                            return item;
                        });
                        if (startsWith) {
                          return startsWith
                        } else return null
                      })
                      updatedData = tempSearch
                    }
                    
                    if (status.length) {
                      let tempStatus = updatedData
                      let tempSearch = tempStatus.filter(item => {
                        let tempItem = item.status ? item.status.toLowerCase() : ''
                        var tempItemNew = tempItem ? tempItem.replace(/-/g, ' ') : tempItem;
                        const startsWith = status.find(function (post, index) {
                          let tempPost = post ? post.toLowerCase() : post
                          var tempPostNew = tempPost ? tempPost.replace(/-/g, ' ') : tempPost;
                          if (tempPostNew == tempItemNew)
                            return item;
                        });
                        if (startsWith) {
                          return startsWith
                        } else return null
                      })
                      updatedData = tempSearch
                    }
                    if (status.length || searchType.length || search.length) {
                      console.log(updatedData)
                      return updatedData
                    } else {
                      return assignments
                    }
                  }
                  
                  // ** Function to handle status filter  
                  const handleStatusFilter = e => {
                    const value = e.target.value
                    let arr = status;
                    
                    if (arr.includes(value)) {
                      arr.splice(arr.indexOf(value), 1);
                    } else {
                      arr.push(value);
                    }
                    setStatus(arr)
                    let id = e.target.id;
                    setSearchStatusCheck({ ...searchStatusCheck, [id]: !searchStatusCheck[id] });
                  }
                  
                  
                  // ** Function to handle type filter  
                  const handleTypeFilter = e => {
                    const value = e.target.value
                    let arr = searchType;
                    if (arr.includes(value)) {
                      arr.splice(arr.indexOf(value), 1);
                    } else {
                      arr.push(value);
                    }
                    setSearchType(arr);
                    let id = e.target.id;
                    setSearchTypeCheck({ ...searchTypeCheck, [id]: !searchTypeCheck[id] });
                  }
                  
                  // ** Function to handle type filter  
                  const handleSearchFilter = e => {
                    const value = e.target.value;
                    setSearch(value);
                  }
                  
                  
                  useEffect(() => {
                    setLoading(true)
                    let mounted = true;
                    GetSubjectAssignments({ sid: subjectId })
                    .then((res) => {
                      if (mounted) {
                        const finalData = res.data.map(item =>{
                          if(item.status == "Closed-Marked" && item.notify_assessed == 0){
                            item.status = "Closed-Submitted";
                          }
                          return item;
                        })
                        setAssignments(finalData);
                      }
                      if (res && res.data && res.data.length == 0) {
                        setNoDataComponent(Str.noRecord)
                      }
                      setRenderTable(true)
                    })
                    .catch((error) => { });
                    setTimeout(() => {
                      setLoading(false)
                    }, 3000);
                    return function cleanup() {
                      mounted = false;
                    };
                  }, [subjectId]);
                  
                  const viewMe = (id, view) => {
                    parentCall(id, view);
                  };
                  
                  const resetFilter = () => {
                    setSearchType([]);
                    setSearchTypeCheck({
                      "action1": false,
                      "action2": false,
                      "action3": false,
                      "action4": false,
                      "action5": false,
                      "action6": false,
                      "action7": false,
                      "action9": false,
                      "action10": false,
                      "action15": false,
                      "action16": false,
                      "action17": false,
                      "action18": false,
                      "action19": false,
                      "action20": false,
                    });
                    setStatus([]);
                    setSearchStatusCheck({
                      "action8": false,
                      "action9": false,
                      "action10": false,
                      "action11": false,
                      "action12": false,
                      "action13": false,
                      "action14": false,
                    });
                    setSearch('')
                  };
                  
                  
                  return (
                    <>
                    <div className="custom-table-div custom-table-div2 filter-search-icon card card-table-custom">
                    <div className="filter-button-group">
                    <div className="filter-eff filter-data-btn">
                    <button className="filter-buttons">
                    <i className="fal fa-filter"></i>
                    </button>
                    </div>
                    <div className="assessment-table-filter">
                    <div className="btn-type-filter dropdown-comman">
                    <div className="dropdown multiselect">
                    <button
                    className={`btn btn-default dropdown-toggle ${searchType.length ? 'btn-selected' : ''}`}
                    type="button"
                    id="typeBtnId"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="false"
                    title="Type"
                    >
                    <span>
                    Type <i className="fal fa-angle-down"></i>
                    </span>
                    </button>
                    <form className="dropdown-menu remove-list-style">
                    <ul aria-labelledby="typeBtnId">
                    <li className="item-text-blue">
                    <input
                    type="checkbox"
                    id="action1"
                    onClick={handleTypeFilter}
                    value="Assignment"
                    checked={searchTypeCheck.action1}
                    />
                    <label htmlFor="action1">
                    <i className="fal fa-file"></i> Assignment
                    </label>
                    </li>
                    <li className="item-text-green">
                    <input
                    type="checkbox"
                    id="action2"
                    onClick={handleTypeFilter}
                    value="Internal Exam"
                    checked={searchTypeCheck.action2}
                    />
                    <label htmlFor="action2">
                    <i className="fal fa-file"></i> Internal Exam
                    </label>
                    </li>
                    <li className="item-text-yellow">
                    <input
                    type="checkbox"
                    id="action3"
                    onClick={handleTypeFilter}
                    value="External Exam"
                    checked={searchTypeCheck.action3}
                    />
                    <label htmlFor="action3">
                    <i className="fal fa-university"></i> External Exam
                    </label>
                    </li>
                    <li className="item-text-purple">
                    <input
                    type="checkbox"
                    id="action4"
                    onClick={handleTypeFilter}
                    value="Test"
                    checked={searchTypeCheck.action4}
                    />
                    <label htmlFor="action4">
                    <i className="fal fa-file"></i> Test
                    </label>
                    </li>
                    <li className="item-text-blue">
                    <input
                    type="checkbox"
                    id="action5"
                    onClick={handleTypeFilter}
                    value="PoE-Formative"
                    checked={searchTypeCheck.action5}
                    />
                    <label htmlFor="action5">
                    <i className="fal fa-folder-tree"></i> PoE-Formative
                    </label>
                    </li>
                    <li className="item-text-green">
                    <input
                    type="checkbox"
                    id="action6"
                    onClick={handleTypeFilter}
                    value="PoE-Summative"
                    checked={searchTypeCheck.action6}
                    />
                    <label htmlFor="action6">
                    <i className="fal fa-folder-tree"></i> PoE-Summative
                    </label>
                    </li>
                    <li className="item-text-purple">
                    <input
                    type="checkbox"
                    id="action7"
                    onClick={handleTypeFilter}
                    value="PoE-Informal Activities"
                    checked={searchTypeCheck.action7}
                    />
                    <label htmlFor="action7">
                    <i className="fal fa-folder-tree"></i> PoE-Informal Activities
                    </label>
                    </li>
                    <li className="item-text-blue">
                    <input
                    type="checkbox"
                    id="action15"
                    onClick={handleTypeFilter}
                    value="Survey"
                    checked={searchTypeCheck.action15}
                    />
                    <label htmlFor="action15">
                    <i className="fal fa-file"></i> Survey
                    </label>
                    </li>
                    <li className="item-text-blue">
                    <input
                    type="checkbox"
                    id="action16"
                    onClick={handleTypeFilter}
                    value="Sick Test"
                    checked={searchTypeCheck.action16}
                    />
                    <label htmlFor="action16">
                    <i className="fal fa-file"></i> Sick Test
                    </label>
                    </li>
                    <li className="item-text-yellow">
                    <input
                    type="checkbox"
                    id="action18"
                    onClick={handleTypeFilter}
                    value="KM Exam"
                    checked={searchTypeCheck.action18}
                    />
                    <label htmlFor="action18">
                    <i className="fal fa-file"></i> KM Exam
                    </label>
                    </li>

                    <li className="item-text-red">
                    <input
                    type="checkbox"
                    id="action19"
                    onClick={handleTypeFilter}
                    value="PM Exam"
                    checked={searchTypeCheck.action19}
                    />
                    <label htmlFor="action19">
                    <i className="fal fa-file"></i> PM Exam
                    </label>
                    </li>

                    <li className="item-text-yellow">
                    <input
                    type="checkbox"
                    id="action20"
                    onClick={handleTypeFilter}
                    value="Special Exam"
                    checked={searchTypeCheck.action20}
                    />
                    <label htmlFor="action20">
                    <i className="fal fa-file"></i> Special Exam
                    </label>
                    </li>

                    <li className="item-text-blue">
                    <input
                    type="checkbox"
                    id="action17"
                    onClick={handleTypeFilter}
                    value="Supplementary Exam"
                    checked={searchTypeCheck.action17}
                    />
                    <label htmlFor="action17">
                    <i className="fal fa-file"></i> Supplementary Exam
                    </label>
                    </li>
                    </ul>
                    </form>
                    </div>
                    </div>
                    </div>
                    <div className="assessment-table-status">
                    <div className="btn-type-Status dropdown-comman">
                    <div className="dropdown multiselect">
                    <button
                    className={`btn btn-default dropdown-toggle ${status.length ? 'btn-selected' : ''}`}
                    type="button"
                    id="dropdownMenu1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                    title="Status"
                    >
                    <span>
                    Status <i className="fal fa-angle-down"></i>
                    </span>
                    </button>
                    <form className="dropdown-menu remove-list-style">
                    <ul aria-labelledby="dropdownMenu1">
                    <li className="item-text-red">
                    <input
                    type="checkbox"
                    id="action8"
                    value="Closed Not-Submitted"
                    onClick={handleStatusFilter}
                    checked={searchStatusCheck.action8}
                    />
                    <label htmlFor="action8">
                    <span className="as-red-bg as-widget">CLOSED NOT-SUBMITTED</span>
                    </label>
                    </li>
                    <li className="item-text-blue">
                    <input
                    type="checkbox"
                    id="action9"
                    onClick={handleStatusFilter}
                    value="Closed-Marked"
                    checked={searchStatusCheck.action9}
                    />
                    <label htmlFor="action9">
                    <span className="as-blue-bg as-widget">CLOSED-MARKED</span>
                    </label>
                    </li>
                    <li className="item-text-yellow">
                    <input
                    type="checkbox"
                    id="action10"
                    onClick={handleStatusFilter}
                    value="Closed-Submitted"
                    checked={searchStatusCheck.action10}
                    />
                    <label htmlFor="action10">
                    <span className="as-yellow-bg as-widget">CLOSED-SUBMITTED</span>
                    </label>
                    </li>
                    <li className="item-text-green">
                    <input
                    type="checkbox"
                    id="action11"
                    onClick={handleStatusFilter}
                    value="Submitted"
                    checked={searchStatusCheck.action11}
                    />
                    <label htmlFor="action11">
                    <span className="as-green-bg as-widget">SUBMITTED</span>
                    
                    </label>
                    </li>
                    <li className="item-text-yellow">
                    <input
                    type="checkbox"
                    id="action12"
                    onClick={handleStatusFilter}
                    value="Pending"
                    checked={searchStatusCheck.action12}
                    />
                    <label htmlFor="action12">
                    <span className="as-yellow-bg as-widget">PENDING</span>
                    
                    </label>
                    </li>
                    <li className="item-text-green">
                    <input
                    type="checkbox"
                    id="action13"
                    onClick={handleStatusFilter}
                    value="Draft"
                    checked={searchStatusCheck.action13}
                    />
                    <label htmlFor="action13">
                    <span className="as-green-bg as-widget">DRAFT</span>
                    </label>
                    </li>
                    <li className="item-text-blue">
                    <input
                    type="checkbox"
                    id="action14"
                    onClick={handleStatusFilter}
                    value="Open"
                    checked={searchStatusCheck.action14}
                    />
                    <label htmlFor="action14">
                    <span className="as-blue-bg as-widget">OPEN</span>
                    </label>
                    </li>
                    </ul>
                    </form>
                    </div>
                    </div>
                    </div>
                    <div className="button-reset">
                    <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                    <i className="fal fa-redo"></i>Reset
                    </button>
                    </div>
                    </div>
                    
                    <div className="data-table-extensions">
                    <div className="data-table-extensions-filter">
                    <label htmlFor="filterDataTable" className="icon"></label>
                    <input
                    type="text"
                    name="filterDataTable"
                    className="filter-text"
                    placeholder="Search"
                    onChange={handleSearchFilter}
                    value={search}
                    />
                    </div>
                    <div className="data-table-extensions-action"></div>
                    </div>
                    {!renderTable && <SkeletonTicketList />}
                    {renderTable &&
                      <DataTable
                      title="Assessments"
                      columns={columns}
                      data={dataToRender()}
                      pagination={true}
                      noDataComponent={noDataComponent}
                      defaultSortField="close_date_time"
                      defaultSortAsc={false}
                      />
                    }
                    </div>
                    </>
                  );
                };
                export default SubjectAssignmentsLatest;
                
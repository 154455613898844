import React, { useState, useEffect, useRef } from "react";
import ProgressForm from "./re_registration_sub/ProgressForm";
import RedoSubject from "./re_registration_sub/RedoSubject";
import ReWriteExam from "./re_registration_sub/ReWriteExam";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { submitReRegistrationService } from "../../services/ReRegistrationService";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setReRegistrationsReload } from "../../store/actions";
import DigitalSignatureComponent from "../common/DigitalSignatureComponent";
function SchoolOfDraughting(props) {
  const { reRegSubject, registration, setReadOnly, setStudentNewRegId, lateReg } = props;

  const [parentSubjectRedoLength, setParentSubjectRedoLength] = useState([
    { subject: "" },
  ]);
  const [parentSubjectReWriteLength, setparentSubjectReWriteLength] = useState([
    { subject: "" },
  ]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [delivery_methode, setDelivery_methode] = useState('online');  
  const [progress, setProgress] = useState('');  
  const [continueChecked, setContinueChecked] = useState(false);
  const [disableProgress, setDisableProgress] = useState(false);
  const [disableRe, setDisableRe] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const isReReg = useSelector(state=>state.isReReg);
  const reload = useSelector(state => state.registrations.reload);
  const [isSig, setIsSig] = useState("")
  const signaturePadRef = useRef(null);
  const fileInputRef = useRef(null);
  const [ipAddress, setIpAddress] = useState('');
  const [cDate,setCDate] = useState('')

  
  const handleCheck = (e) => {
    setChecked(e.target.checked);
  };

  const handleDelivery_methode = (e) => {
    setDelivery_methode(e.target.value);
  };

  const handleCheckContinue = (e) => {
    setContinueChecked(e.target.checked);
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      subjectRedo_: parentSubjectRedoLength,
      subjectReWrite_: parentSubjectReWriteLength,
      term_condn: checked,
      //term_continue: continueChecked,
      delivery_methode: delivery_methode,
      rereg_academy: props.registration.academy,
      rereg_id: props.registration.id,
      next_year_option:progress,
      late_reg : lateReg,
      student_signature : isSig,
      ip: ipAddress,
      signature_date:parseInt(new Date(cDate).getTime()/1000)

    },
    validationSchema: Yup.object({
      delivery_methode: Yup.string().required("Learning Method is Required"),
      // delivery_methode: Yup.string().ensure().when('subjectRedo_', {
      //   is: '',
      //   then: Yup.string().required("Required")
      // }),
      // delivery_methode: Yup.string()
      // .when('subjectRedo_', {
      //   is: (subjectRedo_) => subjectRedo_.length > 0,
      //   then: Yup.string()
      //     .required('Field is required')            
      // }),
      // delivery_methode: Yup.string().when("subjectRedo_", {
      //   is:  (subjectRedo_) => subjectRedo_.length > 0,
      //   then: Yup.required('rdfegerg')
      // }),
      // subjectRedo_: Yup.array()
      //   .of(
      //     Yup.object().shape({
      //       subject: Yup.string().required("Required"), // these constraints take precedence
      //     })
      //   )
      //   .required("Must have friends"),
      // subjectReWrite_: Yup.array()
      //   .of(
      //     Yup.object().shape({
      //       subject: Yup.string().required("Required"), // these constraints take precedence
      //     })
      //   )
      //   .required("Must have friends"),
      student_signature : Yup.mixed().required('Signature is required'),
      term_condn: Yup.bool().oneOf(
        [true],
        "Accept Terms & Conditions is required"
      ),
      term_continue: Yup.bool().oneOf([true], "Required"),
      // next_year_option:Yup.string().when("subjectRedo_", {
      //   is : (subjectRedo_) => !disableProgress || disableRe,
      //   then : Yup.string().required("Progress is required")
      // }) 
    }),
    onSubmit: (values) => {
      if(values.subjectRedo_[0].subject==''){
        values.delivery_methode='online';
      }
      const formData = new FormData();

const appendFormData = (data) => {
  Object.keys(data).forEach(key => {
    const value = data[key];

    if (Array.isArray(value)) {
      // For array values, stringify the entire array
      formData.append(key, JSON.stringify(value));
    } else if (typeof value === 'object' && value !== null) {
      if (value instanceof File) {
        // Handle file objects
        formData.append(key, value);
      } else {
        // Stringify other objects
        formData.append(key, JSON.stringify(value));
      }
    } else {
      // Append primitive values directly
      formData.append(key, value);
    }
  });
};



// Convert the object to FormData
appendFormData(values);
  //     const formData = new FormData();
  
  // Object.keys(values).forEach(key => {
  //   // Handle different types of values
  //   if (values[key] instanceof File) {
  //     // If it's a file, append directly
  //     formData.append(key, values[key]);
  //   } else if (Array.isArray(values[key])) {
  //     // If it's an array, convert to comma-separated string
  //     formData.append(key, values[key].join(','));
  //   } else if (typeof values[key] === 'object' && values[key] !== null) {
  //     // If it's an object, convert to JSON string
  //     formData.append(key, JSON.stringify(values[key]));
  //   } else {
  //     // For primitive types (string, number, boolean)
  //     formData.append(key, values[key]);
  //   }
  // });
      submitReRegistrationService(formData).then((res) => {
       
        Swal.fire({
          icon: "success",
          title: "Successfully Submitted",
          text: "This does not confirm approval of your re-registration!!",
          timer: 3000,
          onOpen: function () {
            Swal.showLoading();
          },
        }).then(function (result) {
          setLoading(true);
          setStudentNewRegId(res.data.studentNewRegId);
          setReadOnly(true);
          props.updateStatus();
          if(isReReg.isReReg===true){
            dispatch(setReRegistrationsReload(!reload));
            history.push("/reregStatus");
          }
        });
      });
    },
  });

  const handleProgressChange = (e) =>{    
    setProgress(e.target.value);      
    handleDisableField(e.target.value, "progress");
  }

  const handleDisableField = (value, field) =>{
    if(field=="re-write" || field == "re-do"){
      let has_value = value.filter(item => item.subject!="").length > 0;
      if(field=="re-write"){
        has_value = (value.filter(item => item.subject!="").length > 0) || (parentSubjectRedoLength.filter(item => item.subject!="").length > 0)
      }
      if(field=="re-do"){
        has_value = (value.filter(item => item.subject!="").length > 0) || (parentSubjectRedoLength.filter(item => item.subject!="").length > 0)
      }
      if(has_value){
        setDisableProgress(true);
      }else{
        setDisableProgress(false)
      }
    }
    if(field=="progress"){
      if(value != ""){
        setDisableRe(true)
      }else{
        setDisableRe(false)
      }
    }
  }
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="card-body">
          <div className="theme-form application-form">
            <div className="row">
              <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">
                  Rewrite Internal Exam Only (optional, if applicable):
                </h5>
                <label>
                  Some students may re-write internal exams. This is advised if
                  the assessments were passed, but the year mark reflects below
                  50% due to a failed exam. Select subjects for internal exams
                  to be rewritten:
                </label>

                <ReWriteExam
                  disable = {disableRe}                  
                  handleDisableField = {handleDisableField}
                  formik={formik}
                  setparentSubjectReWriteLength={setparentSubjectReWriteLength}
                  parentSubjectReWriteLength={parentSubjectReWriteLength}
                  reRegSubject={reRegSubject}
                />
              </div>
              <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">
                  Redo (optional, if applicable):
                </h5>
                <label>
                  Select subjects you want to repeat, as term mark is either
                  below 50% or student would like to attempt it again. If you do
                  not need/want to repeat any subjects you may leave this field
                  blank.
                </label>

                <RedoSubject
                  disable = {disableRe}
                  handleDisableField = {handleDisableField}
                  formik={formik}
                  setParentSubjectRedoLength={setParentSubjectRedoLength}
                  parentSubjectRedoLength={parentSubjectRedoLength}
                  reRegSubject={reRegSubject}
                  setDelivery_methode={setDelivery_methode}
                />
              </div>
              <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">
                  Progress:
                </h5>
                <label>
                <b>SSD</b> In order to be eligible to progress into the second year of SSD please ensure you have 65% across all your modules
                </label>
                <div className="row">
                  <div className="col-sm-6 form-group inputfield">
                    <select
                      className={
                        "form-control " +
                        (formik.touched.next_year_option && formik.errors.next_year_option
                          ? " is-invalid"
                          : "")
                      }
                      name="next_year_option"
                      onChange={(e) =>{handleProgressChange(e)}}
                      onBlur={formik.handleBlur}
                      value={formik.values.next_year_option}
                      disabled={disableProgress}
                    >
                      <option value="">---</option>
                      <option value="Progress to SSD 2nd Year">Progress to SSD 2nd Year</option>                    
                    </select>
                    {formik.touched.next_year_option &&
                        formik.errors.next_year_option ? (
                          <div className="invalid-feedback">
                            {formik.errors.next_year_option}
                          </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">Learning Method (only applies to Redo subjects)</h5>
                <label>
                Select the relevant learning method that you would like to proceed with for the Redo subjects only.
Kindly note that the internal rewrites are offered strictly via the Online learning method.
                </label>
              </div> 
              <div className="col-6 form-group inputfield">
                <select
                  className={
                    "form-control " +
                    (formik.touched.delivery_methode && formik.errors.delivery_methode
                      ? " is-invalid"
                      : "")
                  }
                  name="delivery_methode"
                  onChange={(e) =>{handleDelivery_methode(e)}}
                  onBlur={formik.handleBlur}
                  value={formik.values.delivery_methode}
                >
                  <option value="">---</option>
                  <option value="full-time">Full Time</option>
                  <option value="online">Online</option>
                  <option value="fulltimeonline">Full Time, Online</option>
                  <option value="fulltimecontact">Full Time, Contact</option>
                  <option value="parttimeonline">Part-Time, Online</option>
                </select>
                {formik.touched.delivery_methode &&
                    formik.errors.delivery_methode ? (
                      <div className="invalid-feedback">
                        {formik.errors.delivery_methode}
                      </div>
                ) : null}
                  <DigitalSignatureComponent formik={formik} setCDate={setCDate} setIpAddress={setIpAddress} ipAddress={ipAddress} cDate={cDate} signaturePadRef={signaturePadRef} fileInputRef={fileInputRef} setIsSig={setIsSig}/>
              </div>
              {/* <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">Progress From:</h5>
              </div> */}

              <div className="col-12 form-group inputfield">
                {/*<div className="custom-control custom-checkbox text-left box-tm">
                  <input
                    className={
                      "custom-control-input " +
                      (formik.touched.term_continue &&
                      formik.errors.term_continue
                        ? " is-invalid"
                        : "")
                    }
                    type="checkbox"
                    name="term_continue"
                    onChange={(e) => handleCheckContinue(e)}
                    onBlur={formik.handleBlur}
                    checked={continueChecked}
                    id="customCheck1"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheck1"
                  >
                  I would like to continue to MDDOP N5.
                  </label>

                  {formik.touched.term_continue &&
                  formik.errors.term_continue ? (
                    <div className="invalid-feedback">
                      {formik.errors.term_continue}
                    </div>
                  ) : null}
                </div>
                <br />*/}
                <div className="custom-control custom-checkbox text-left box-tm">
                  <input
                    className={
                      "custom-control-input " +
                      (formik.touched.term_condn && formik.errors.term_condn
                        ? " is-invalid"
                        : "")
                    }
                    type="checkbox"
                    name="term_condn"
                    onChange={(e) => handleCheck(e)}
                    onBlur={formik.handleBlur}
                    checked={checked}
                    id="customCheck2"
                  />
                  <label
                    className="custom-control-label color-red"
                    htmlFor="customCheck2"
                  >
                    I hereby confirm that I have read, understood and accepted
                    all <a href='https://www.aie.ac/pgs-142-terms-and-conditions.html' target="_blank">Terms and Conditions</a> as set out in the application form that the above subject selections are correct, and understand
                    that there may be changes made based on the academic outcome.
                  </label>
                  {formik.touched.term_condn && formik.errors.term_condn ? (
                    <div className="invalid-feedback">
                      {formik.errors.term_condn}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="Fmfooter">
            <button
              title="Submit"
              type="submit"
              className="btn btn-submit btn-main btn-primary"
              disabled={loading}
            >
              {loading && <i className="fas fa-cog fa-spin"></i>} Submit
            </button>
            <button title="Cancel" className="btn btn-submit btn-main btn-danger ml-2">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
export default SchoolOfDraughting;

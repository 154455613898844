import React from "react";

const AssignmentType = (props) => {
    switch (props.ass_type) {
        case "Assignment":
        return (
          <>
            <div className="assessment-01">
                <p className="as-text-blue"><i className="fal fa-file"></i> <span className="overflow-ellipsis2">Assignment</span></p>
            </div>
          </>
        );
        break;
        case "Internal Exam":
            return (
              <>
                <div className="assessment-01">
                    <p className="as-text-green"><i className="fal fa-file"></i> <span className="overflow-ellipsis2">Internal Exam</span></p>
                </div>
              </>
            );
        break;
        case "External Exam":
            return (
              <>
                <div className="assessment-01">
                <p className="as-text-orange"><i className="fal fa-university"></i> <span className="overflow-ellipsis2">External Exam</span></p>
                </div>
              </>
            );
        break;
        case "Test":
            return (
              <>
                <div className="assessment-01">
                <p className="as-text-red"><i className="fal fa-file"></i> <span className="overflow-ellipsis2">Test</span></p>
                </div>
              </>
            );
        break;

        case "PoE-Formative":
            return (
              <>
                <div className="assessment-01">
                <p className="as-text-blue"><i className="fal fa-folder-tree"></i> <span className="overflow-ellipsis2">PoE-Formative</span></p>
                </div>
              </>
            );
        break;

        case "PoE-Summative":
            return (
              <>
                <div className="assessment-01">
                <p className="as-text-green"><i className="fal fa-folder-tree"></i> <span className="overflow-ellipsis2">PoE-Summative</span></p>
                </div>
              </>
            );
        break;

        case "PoE-Informal Activities":
            return (
              <>
                <div className="assessment-01">
                <p className="as-text-red"><i className="fal fa-folder-tree"></i> <span className="overflow-ellipsis2">PoE-Informal Activities</span></p>
                </div>
              </>
            );
        break;

        case "Survey":
            return (
              <>
                <div className="assessment-01">
                <p className="as-text-blue"><i className="fal fa-file"></i> <span className="overflow-ellipsis2">Survey</span></p>
                </div>
              </>
            );
        break;
        case "Supplementary Exam":
            return (
              <>
                <div className="assessment-01">
                <p className="as-text-blue"><i className="fal fa-file"></i> <span className="overflow-ellipsis2">Supplementary Exam</span></p>
                </div>
              </>
            );
        break;
        case "Sick Test":
            return (
              <>
                <div className="assessment-01">
                <p className="as-text-blue"><i className="fal fa-file"></i> <span className="overflow-ellipsis2">Sick Test</span></p>
                </div>
              </>
            );
        break;
        case "KM Exam":
            return (
              <>
                <div className="assessment-01">
                <p className="as-text-orange"><i className="fal fa-file"></i> <span className="overflow-ellipsis2">KM Exam</span></p>
                </div>
              </>
            );
        break;
        case "PM Exam":
            return (
              <>
                <div className="assessment-01">
                <p className="as-text-red"><i className="fal fa-file"></i> <span className="overflow-ellipsis2">PM Exam</span></p>
                </div>
              </>
            );
        break;
        case "Special Exam":
            return (
              <>
                <div className="assessment-01">
                <p className="as-text-orange"><i className="fal fa-file"></i> <span className="overflow-ellipsis2">Special Exam</span></p>
                </div>
              </>
            );
        break;
        default:
        return (
          <>
            <div className="assessment-01">
                <p className="as-red"><i className="fal fa-times"></i>Unknown Status</p>
            </div>
          </>
        );
        break;
    }
}
export default AssignmentType;

import axiosInstance from "../utils/axios";

export const GetReRegistrationList = async () => {
  return await axiosInstance().get("/reRegistrationList");
};

export const getReRegistrationService = async (regid) => {
  return await axiosInstance().get("/getReRegistration", {
    params: { regid: regid },
  });
};

export const getReRegistrationSubjectService = async (value) => {
  return await axiosInstance().get("getReRegistrationSubject", {
    params: { regid: value },
  });
};

export const getReRegistrationReadOnlySubjectService = async (values) => {
  return await axiosInstance().get("getReRegistrationReadOnlySubject", {
    params: { studentNewRegId: values },
  });
};

export const submitReRegistrationService = async (values) => {
  return await axiosInstance().post("submitReRegistration", values);
};


export const GetReRegNotSubmitReasonsList = async () => {
  return await axiosInstance().get("/getReRegNotSubmitReasonsList");
};

export const ReRegistrationRemindMeLater = async (values) => {
  return await axiosInstance().post("/reRegistrationRemindMeLater",values);
};

export const ArticulationRegistrationList = async () => {
  return await axiosInstance().get("/ArticulationRegistrationList");
};

// export const GetArticulationProgramms = async (value) => {
//   return await axiosInstance().get(`/getArticulationProgramms?articulation_id=${value}`);
// };

export const articulationRegistrationRemindMeLater = async (value) => {
  return await axiosInstance().post(`/articulationRegistrationRemindMeLater`,value);
};

export const WitheldStatusRemindMeLater = async () => {
  return await axiosInstance().post(`/WitheldStatusRemindMeLater`);
};

export const submitArticulationRegistration = async (value) => {
  return await axiosInstance().post(`/submitArticulationRegistration`,value);
};

export const GetQualificationsList = async (value) => {
  return await axiosInstance().get(`/getQualification/?viaSchool=${value?.school}&viaLearning=${value?.learningMethod}&uniqueDataKey=${value?.uniqueDataKey}`);
};

export const GetProgressionSubjects = async (value) => {
  return await axiosInstance().post(`/getProgressionSubjects`,value);
};

export const CheckProgressionSubject = async (value) => {
  return await axiosInstance().get(`/checkProgressionSubjects`,{params : value});
};


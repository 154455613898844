import React, { useState, useEffect, useRef } from "react";
import ProgressForm from "./re_registration_sub/ProgressForm";
import RedoSubject from "./re_registration_sub/RedoSubject";
import ReWriteExam from "./re_registration_sub/ReWriteExam";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { submitReRegistrationService } from "../../services/ReRegistrationService";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setReRegistrationsReload } from "../../store/actions";
import DigitalSignatureComponent from "../common/DigitalSignatureComponent";

function SchoolOfBeauty(props) {
  const { reRegSubject, registration, setReadOnly, setStudentNewRegId, lateReg } = props;

  const [parentSubjectProgLength, setparentSubjectProgLength] = useState([]);
  const [parentSubjectRedoLength, setParentSubjectRedoLength] = useState([
    { subject: "" },
  ]);
  const [parentSubjectReWriteLength, setparentSubjectReWriteLength] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const isReReg = useSelector(state=>state.isReReg);
  const reload = useSelector(state => state.registrations.reload);
  const [isSig, setIsSig] = useState("")
  const signaturePadRef = useRef(null);
  const fileInputRef = useRef(null);
  const [ipAddress, setIpAddress] = useState('');
  const [cDate,setCDate] = useState('')

  const handleCheck = (e) => {
    setChecked(e.target.checked);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      subjectProgress_: parentSubjectProgLength,
      subjectRedo_: parentSubjectRedoLength,
      subjectReWrite_: parentSubjectReWriteLength,
      rereg_academy: props.registration.academy,
      rereg_id: props.registration.id,
      term_condn: checked,
      late_reg : lateReg,
      student_signature : isSig,
      ip: ipAddress,
      signature_date:parseInt(new Date(cDate).getTime()/1000)
    },
    validationSchema: Yup.object({
      // subjectProgress_: Yup.array()
      //   .of(
      //     Yup.object().shape({
      //       subject: Yup.string().required("Required"), // these constraints take precedence
      //     })
      //   )
      //   .required("Must have friends"),
      // subjectRedo_: Yup.array()
      //   .of(
      //     Yup.object().shape({
      //       subject: Yup.string().required("Required"), // these constraints take precedence
      //     })
      //   )
      //   .required("Must have friends"),
      // subjectReWrite_: Yup.array()
      //   .of(
      //     Yup.object().shape({
      //       subject: Yup.string().required("Required"), // these constraints take precedence
      //     })
      //   )
      //   .required("Must have friends"),
      student_signature : Yup.mixed().required('Signature is required'),
      term_condn: Yup.bool().oneOf(
        [true],
        "Accept Terms & Conditions is required"
      ),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
    
      // Append all values from the form
      const appendFormData = (data) => {
        Object.keys(data).forEach(key => {
          const value = data[key];
      
          if (Array.isArray(value)) {
            // For array values, stringify the entire array
            formData.append(key, JSON.stringify(value));
          } else if (typeof value === 'object' && value !== null) {
            if (value instanceof File) {
              // Handle file objects
              formData.append(key, value);
            } else {
              // Stringify other objects
              formData.append(key, JSON.stringify(value));
            }
          } else {
            // Append primitive values directly
            formData.append(key, value);
          }
        });
      };
      appendFormData(values)
      submitReRegistrationService(formData).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Successfully Submitted",
          text: "This does not confirm approval of your re-registration!!",
          timer: 3000,
          onOpen: function () {
            Swal.showLoading();
          },
        }).then(function (result) {
          setLoading(true);
          setStudentNewRegId(res.data.studentNewRegId);
          setReadOnly(true);
          props.updateStatus();
          if(isReReg.isReReg===true){
            dispatch(setReRegistrationsReload(!reload));
            history.push("/reregStatus");
          }
        });
      });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="card-body disabled">
          <div className="theme-form application-form">
            <div className="row">
              {/* <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">Re-Write Exam Only:</h5>
                <label>
                  Select subjects to write exam again, as minimum
                  requirements/marks were not previously obtained. If you do not
                  want to re-write any subjects, then leave blank.
                </label>
                <ReWriteExam
                  formik={formik}
                  setparentSubjectReWriteLength={setparentSubjectReWriteLength}
                  parentSubjectReWriteLength={parentSubjectReWriteLength}
                  reRegSubject={reRegSubject}
                />
              </div> */}
          <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5"> Re-register Modules:</h5>
                <label>

                <b>NOTE:</b> It is mandatory to re-register for EVERY module you have failed. Failure to do so will result in a delay in your academic progress. <br/><br/>
                
                <b>Modules you did not pass and must re-register for:</b>
                </label>
                <RedoSubject
                  formik={formik}
                  setParentSubjectRedoLength={setParentSubjectRedoLength}
                  parentSubjectRedoLength={parentSubjectRedoLength}
                  reRegSubject={reRegSubject}
                  setDelivery_methode={()=>{}}
                  handleDisableField={()=>{}}
                />
                <DigitalSignatureComponent formik={formik} fileInputRef={fileInputRef} signaturePadRef={signaturePadRef} setCDate={setCDate} setIpAddress={setIpAddress} cDate={cDate} ipAddress={ipAddress} setIsSig={setIsSig}/>
              </div>
              <div className="col-12 form-group inputfield">
                <div className="custom-control custom-checkbox text-left box-tm">
                  <input
                    className={
                      "custom-control-input " +
                      (formik.touched.term_condn && formik.errors.term_condn
                        ? " is-invalid"
                        : "")
                    }
                    type="checkbox"
                    name="term_condn"
                    onChange={(e) => handleCheck(e)}
                    onBlur={formik.handleBlur}
                    checked={checked}
                    id="customCheck1"
                  />

                  <label
                    className="custom-control-label textdanger"
                    htmlFor="customCheck1"
                  >
                    I hereby confirm that I have read, understood and accepted
                    all <a href='https://www.aie.ac/pgs-142-terms-and-conditions.html' target="_blank">Terms and Conditions</a> as set out in the application form that the above subject selections are correct, and understand
                    that there may be changes made based on the academic outcome.
                  </label>
                  {formik.touched.term_condn && formik.errors.term_condn ? (
                    <div className="invalid-feedback">
                      {formik.errors.term_condn}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="Fmfooter">
            <button
              title="Submit"
              type="submit"
              className="btn btn-submit btn-main btn-primary"
              disabled={loading}
            >
              {loading && <i className="fas fa-cog fa-spin"></i>} Submit
            </button>
            <button title="Cancel" className="btn btn-submit btn-main btn-danger ml-2">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
export default SchoolOfBeauty;
